import React from 'react';
import { Select, Pagination } from 'antd';
import HeaderPage from "src/components/main/layout/HeaderPage";
import { Link } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import CategoryDtailsModal from 'src/components/modals/CategoryDtailsModal'
import { useEffect, useState } from "react";
import { categoriesProduct as cp } from "src/redux/slices/categoryReducer";
import { useSelector } from "react-redux";
const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}



function Categories() {
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })
    const categoriesProduct = useSelector(cp)

    const [data, setData] = useState([...categoriesProduct])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(categoriesProduct.length / count)

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    useEffect(() => {
        setData(categoriesProduct.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, categoriesProduct])

    useEffect(() => {
        setData(categoriesProduct.slice((page - 1) * count, page * count))
    }, [pages, page, categoriesProduct])


    useEffect(() => {
        setData(categoriesProduct)
    }, [categoriesProduct])

    return (
        <>
            <HeaderPage title={'دسته بندی ها '} subtitle={'دسته بندی ها '} route={{ name: "دسته بندی ها ", url: "/categories" }} />

            {isModalOpen.status && <CategoryDtailsModal setOpen={setIsModalOpen} open={isModalOpen} />}


            <div id="categories-admin">
                <div id="categories-sec1">

                    <div className="add-showPage">
                        <Link to="/add-category">
                            <span>افزودن دسته بندی</span>
                            <i className="fa-solid fa-plus fa-xs"></i>
                        </Link>

                        <div className="show-page">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>

                    </div>



                    <div className="categories-typeOf">
                        <div className="row1">
                            {data.map((product) =>
                                <div className="item">
                                    <div className="top-item">
                                        <span>{product.title}</span>
                                        <span>{product.id}</span>
                                    </div>
                                    <div className="bottom-item">
                                        <Link onClick={() => {
                                            setIsModalOpen({ status: true, type: "add" })
                                        }} to="#"><span>مشاهده جزئیات</span></Link>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>



                    <div className="under-table">
                        <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={categoriesProduct.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>

                </div>
            </div >

        </>
    )
}

export default Categories;