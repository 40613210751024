import { createSlice } from "@reduxjs/toolkit";


export const categoriesSlice = createSlice({
    name: "categories",
    initialState: {
        categoryProduct: [
            { title: "شلوار", id: "#2jK254", },
            { title: "پیراهن", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "پیراهن", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "پیراهن", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "پیراهن", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "پیراهن", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "پیراهن", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
            { title: "شلوار", id: "#2jK254", },
        ]
    }
    ,
    reducers: {

    }

})

export const categoriesProduct = (state) => state.categories.categoryProduct

export default categoriesSlice.reducer