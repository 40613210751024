import { createSlice } from "@reduxjs/toolkit";


export const addFinancialSlice = createSlice({
    name: "addFinancial",
    initialState: {
        transactions: [
            { transitionId: "#SK3216 ", userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", type: "ناموفق", orderStatus: false, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", type: "ناموفق", orderStatus: false, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", type: "ناموفق", orderStatus: false, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", type: "ناموفق", orderStatus: false, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
            { transitionId: "#SK3216 ", userName: "mehdi nikzad", date: "۱۴۰۲/۰۸/۲۳", type: "انتقال", orderStatus: true, transactionOrigin: "درگاه پرداخت", price: "۳۰۰,۰۰۰ " },
        ]

    },
    reducers: {

    }
})

export const transactions = (state) => state.addFinancial.transactions

export default addFinancialSlice.reducer