import { createSlice } from "@reduxjs/toolkit";


export const addMangerSlice = createSlice({
    name: "addManager",
    initialState: {
        addManagerTable: [
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { userId: "#SK2540", name: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },

        ]
    },
    reducers: {


    }
})


export const addManagerTable = (state) => state.addManager.addManagerTable

export default addMangerSlice.reducer