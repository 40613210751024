import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App, { loader as appLoader } from '../App';
import AddAddress, { loader as addAddressLoader } from 'src/pages/AddAddress';
import AddDiscount, { loader as addDiscountLoader } from 'src/pages/AddDiscount';
import AddManagers, { loader as addAddManagersLoader} from 'src/pages/AddManagers';
import AdminDashboard, { loader as adminDashboardLoader} from 'src/pages/AdminDashboard';
import AdminLogin, { loader as adminLoginLoader} from 'src/pages/AdminLogin';
import AddCategory, { loader as addCategoryLoader} from 'src/pages/AddCategory';
import Coupons, { loader as couponsLoader} from 'src/pages/Coupons';
import Managers, { loader as managersLoader} from 'src/pages/Managers';
import Scores, { loader as scoresLoader} from 'src/pages/Scores';
import ShoppingCart, { loader as shoppingCartLoader} from 'src/pages/ShoppingCart';
import AddFeature, { loader as addFeatureLoader } from 'src/pages/AddFeature';
import AddProduct, { loader as addProductLoader } from 'src/pages/AddProduct';
import AddCoupon, { loader as addCouponLoader } from 'src/pages/AddCoupon';
import AddFinancial, { loader as AddFinancialLoader } from 'src/pages/AddFinancial';
import AdminProduct, { loader as AdminProductLoader } from 'src/pages/AdminProduct';
import Discounts, { loader as DiscountsLoader } from 'src/pages/Discounts';
import ViewChanges, { loader as ViewChangesLoader } from 'src/pages/ViewChanges';
import Properties, { loader as PropertiesLoader } from 'src/pages/Properties';
import Orders, { loader as ordersLoader } from 'src/pages/Orders';
import Comment, { loader as commentLoader } from 'src/pages/Comment';
import DetailsOrder, { loader as detailsOrderLoader } from 'src/pages/DetailsOrder';
import Categories, { loader as CategoriesLoader } from 'src/pages/Categories';
import Customers, { loader as CustomersLoader } from 'src/pages/Customers'
import SingleProduct, { loader as SingleProductLoader } from 'src/pages/SingleProduct'

 const  router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      loader: appLoader,
      children: [
        {
            path: '/',
            element: <AdminDashboard />,
            loader: adminDashboardLoader,
        },
        {
            path: 'admin-dashboard',
            element: <AdminDashboard />,
            loader: adminDashboardLoader,
        },
        {
            path: 'add-address',
            element: <AddAddress />,
            loader: addAddressLoader,
        },
        {
            path: 'add-discount',
            element: <AddDiscount />,
            loader: addDiscountLoader,
        },
        {
            path: 'add-managers',
            element: <AddManagers />,
            loader: addAddManagersLoader,
        },
        {
            path: 'admin-login',
            element: <AdminLogin />,
            loader: adminLoginLoader,
        },
        {
            path: 'add-category',
            element: <AddCategory />,
            loader: addCategoryLoader,
        },
        {
            path: 'coupons',
            element: <Coupons />,
            loader: couponsLoader,
        },
        {
            path: 'managers',
            element: <Managers />,
            loader: managersLoader,
        },
        {
            path: 'scores',
            element: <Scores />,
            loader: scoresLoader,
        },
        {
            path: 'shopping-cart',
            element: <ShoppingCart />,
            loader: shoppingCartLoader,
        },
        {
            path: 'add-discount',
            element: <AddDiscount />,
            loader: addDiscountLoader,
        },
        {
            path: 'add-feature',
            element: <AddFeature />,
            loader: addFeatureLoader,
        },
        {
            path: 'add-product',
            element: <AddProduct />,
            loader: addProductLoader,
        },
        {
            path: 'add-coupon',
            element: <AddCoupon />,
            loader: addCouponLoader,
        }
        ,
        {
            path: 'add-financial',
            element: <AddFinancial />,
            loader: AddFinancialLoader,
        },
        {
            path: 'admin-product',
            element: <AdminProduct />,
            loader: AdminProductLoader,
        },
        {
            path: 'discounts',
            element: <Discounts />,
            loader: DiscountsLoader,
        },
        {
            path: 'view-changes',
            element: <ViewChanges />,
            loader: ViewChangesLoader,
        },
        {
            path: 'orders',
            element: <Orders />,
            loader: ordersLoader,
        },
        {
            path: 'properties',
            element: <Properties />,
            loader: PropertiesLoader,
        },
        {
            path: 'comment',
            element: <Comment />,
            loader: commentLoader,
        },
        {
            path: 'details-order',
            element: <DetailsOrder />,
            loader: detailsOrderLoader,
        },
        {
            path: 'categories',
            element: <Categories />,
            loader: CategoriesLoader,
        },
        {
            path: 'customers',
            element: <Customers />,
            loader: CustomersLoader,
        },
        {
            path: 'single-product',
            element: <SingleProduct />,
            loader: SingleProductLoader
        }
    ]
    },
    
  ])
  
  export default router

