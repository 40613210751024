import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { useSelector } from "react-redux";
import { commentTable as ct } from "src/redux/slices/commentReducer";
import { useEffect, useState } from "react";
import CommentModal from 'src/components/modals/CommentModal'
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({
    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,
});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Comment() {
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })
    const commentTable = useSelector(ct)
    const [data, setData] = useState([...commentTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(commentTable.length / count)


    useEffect(() => {
        setData(commentTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, commentTable])

    useEffect(() => {
        setData(commentTable.slice((page - 1) * count, page * count))
    }, [pages, page, commentTable])


    useEffect(() => {
        setData(commentTable)
    }, [commentTable])
    return (
        <>
            <HeaderPage title={"نظرات"} subtitle={"نظرات"} route={{ name: "نظرات", url: "/comment" }} />
            {isModalOpen.status && <CommentModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="comment-admin">
                <div id="comment-sec1">

                    <div className="search-bar">
                        <div className="top-search">
                            <div>
                                <span>همه</span>
                                <span>تایید شده</span>
                                <span>تایید نشده</span>
                            </div>
                        </div>
                        <div className="middle-search">
                            <div className="search">
                                <label>
                                    جستجو :
                                    <input style={{ height: "36px" }} />
                                </label>
                            </div>
                            <div className="show-page">
                                <label>
                                    ورودی
                                    <Select
                                        defaultValue="10"
                                        style={{
                                            width: 200,
                                        }}
                                        onChange={(value) => { setCount(value) }}
                                        options={[
                                            // { value: 5, label: '5' },
                                            { value: 10, label: '10' },
                                            { value: 15, label: '15' },
                                            { value: 20, label: '20' },
                                        ]}
                                    />

                                    نمایش
                                </label>
                            </div>
                        </div>
                        <div className="add-delete">
                            <Link to="#">تایید</Link>
                            <Link to="#">
                                <span>عدم تایید</span>
                            </Link>
                        </div>
                    </div>



                    <div className="comment-table">
                        <table style={{ borderCollapse: "collapse", borderColor: "#EFF2F7", width: "100%" }}>
                            <thead style={{ height: "49px", backgroundColor: "#EFF2F7" }}>
                                <th><input style={{ width: "16px", height: "16px" }} type="checkbox" checked="checked" /></th>
                                <th>نام کاربری</th>
                                <th>تاریخ</th>
                                <th>محصول مورد نظر</th>
                                <th>...</th>
                            </thead>

                            <tbody style={{ textAlign: "center", verticalAlign: "middle" }}>
                                {data.map((comment) =>
                                    <tr>
                                        <th><input style={{ width: "16px", height: "16px" }} type="checkbox" checked="checked" /></th>
                                        <td>{comment.userName}</td>
                                        <td>{comment.date}</td>
                                        <td>{comment.product}</td>
                                        <td>
                                            <Link to="#"><span onClick={() => {
                                                setIsModalOpen({ status: true, type: "add" })
                                            }}>مشاهده جزئیات</span></Link>
                                        </td>
                                    </tr>
                                )}

                            </tbody>

                        </table>
                    </div>


                    <div className="under-table">
                        <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={commentTable.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>

                </div>
            </div>

        </>


    )
}

export default Comment;