import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { useSelector } from "react-redux";
import { scoreTable as st } from "src/redux/slices/scoresReducer";
import { useEffect, useState } from "react";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Scores() {

    const scoresTable = useSelector(st)
    const [data, setData] = useState([...scoresTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(scoresTable.length / count)


    useEffect(() => {
        setData(scoresTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, scoresTable])

    useEffect(() => {
        setData(scoresTable.slice((page - 1) * count, page * count))
    }, [pages, page, scoresTable])


    useEffect(() => {
        setData(scoresTable)
    }, [scoresTable])
    return (
        <>
            <HeaderPage title={"جدول امتیازات"} subtitle={"جدول امتیازات"} route={{ name: "جدول امتیازات", url: "/scores" }} />

            <div id="ratetable">
                <div className="bala">
                    <div className="search-lable">
                        <label>
                            جستجو :

                            <input type="search" />
                        </label>
                    </div>

                    <label>
                        ورودی
                        <Select
                            defaultValue="10"
                            style={{
                                width: 200,
                            }}
                            onChange={(value) => { setCount(value) }}
                            options={[
                                // { value: 5, label: '5' },
                                { value: 10, label: '10' },
                                { value: 15, label: '15' },
                                { value: 20, label: '20' },
                            ]}
                        />

                        نمایش
                    </label>


                </div>
                <table>
                    <thead>
                        <tr>

                            <th>شناسه امتیاز</th>
                            <th>امتیاز</th>
                            <th>تاریخ</th>
                            <th>شناسه مشتری</th>
                            <th>نام مشتری</th>
                            <th>شناسه محصول</th>
                            <th>عنوان محصول</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((score) =>


                            <tr>
                                <td>{score.scoreId}</td>
                                <td>{score.score}</td>
                                <td>{score.date}</td>
                                <td>{score.costumerName}</td>
                                <td>{score.productTitle}</td>
                            </tr>
                        )}
                    </tbody>


                </table>

                <div className="page">
                    <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                    <Pagination
                        className="rtl-pagination"
                        current={page}
                        total={scoresTable.length}
                        pageSize={count}
                        onChange={(page) => setPage(page)}
                    />
                </div>
            </div>

        </>


    )
}

export default Scores