import { Link } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AdminLogin() {
    return (
        <>

            <div id="admin-login">


                <div id="login-section">

                    <div className="login-content">

                        <div className="logo-fashion">
                            <img src={process.env.PUBLIC_URL + "/assets/images/Fashion Startup.png"}/>
                        </div>

                        <div className="part-login">

                            <div className="part1">

                                <Link to={"#"}>
                                    <span>ورود با شماره تلفن</span>
                                </Link>
                                <Link to={"#"}>
                                    <span>ورود با ایمیل</span>
                                </Link>

                            </div>

                            <div className="part2">

                                <p>خوش آمدید</p>
                                <span>برای ادامه به fashion startup وارد شوید</span>

                            </div>

                            <div className="part3">

                                <div className="user-name-password">

                                    <div className="enter-user-name">

                                        <div className="text">
                                            <span>نام کابری یا ایمیل </span>
                                        </div>

                                        <div className="input-section">
                                            <input type="email" placeholder="نام کاربری یا ایمل خود را وارد کنید"/>
                                        </div>

                                    </div>

                                    <div className="enter-password">

                                        <div className="text">
                                            <span>رمز عبور</span>
                                        </div>

                                        <div className="input-section">
                                            <input type="text" placeholder="رمز عبور خود را وارد کنید"/>
                                        </div>

                                    </div>


                                </div>

                                <div className="login-button">

                                    <div className="text">
                                        <span>مرا به خاطر بسپار</span>
                                        <input type="checkbox"/>
                                    </div>
                                    <Link to={"#"}>
                                        <span>ورود</span>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="footer-login">
                        <span>2024 © Fashion Startup.</span>
                    </div>
                </div>


                <div id="image-section">
                    <img src={process.env.PUBLIC_URL + "/assets/images/Frame 3176.png"}/>
                </div>
            </div>

        </>


    )
}

export default AdminLogin