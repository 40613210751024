import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { useSelector } from "react-redux";
import { detialsTables as dts } from "src/redux/slices/detailsOrderReducer";
import { Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import OrderModal from "src/components/modals/OrderModal";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Scores() {
    const detialsTables = useSelector(dts)
    console.log("djhscvgy", detialsTables);
    const [data, setData] = useState([...detialsTables])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(detialsTables.length / count)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })

    useEffect(() => {
        setData(detialsTables.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, detialsTables])

    useEffect(() => {
        setData(detialsTables.slice((page - 1) * count, page * count))
    }, [pages, page, detialsTables])


    return (
        <>
            <HeaderPage title={"جزئیات سفارش"} subtitle={"جزئیات سفارش"} route={{ name: "جزئیات سفارش", url: "/details-order" }} />

            {isModalOpen.status && <OrderModal setOpen={setIsModalOpen} open={isModalOpen} />}



            <div id="details-order">
                <div id="section-1">
                    <div className="information-details-order">
                        <div className="section-1">
                            <div className="information">
                                <p>زمان تحویل سفارش :</p>
                                <span>۱۴۰۲/۰۸/۱۹</span>
                            </div>
                            <div className="information">
                                <p>کد پیگیری :</p>
                                <span>۴۲۲۹۳۵۶۱۵۲۸۴۶۹۱۰۲۳۵۴۱۱۷۳</span>
                            </div>
                            <div className="information">
                                <p>تاریخ ثبت سفارش : </p>
                                <span>۱۴۰۲/۰۸/۱۲</span>
                            </div>
                        </div>
                        <div className="section-2">
                            <div className="information">
                                <p>هزینه ارسال :</p>
                                <span>۴۰.۰۰۰ تومان</span>
                            </div>
                            <div className="information">
                                <p>مبلغ کل :</p>
                                <span>۵.۶۰۰.۰۰۰ تومان</span>
                            </div>
                            <div className="information">
                                <p>آدرس :</p>
                                <span>گیلان لاهیجان میدان شهدا </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="section-2">

                    <div className="details-order-shows">

                        <label>

                        </label>

                        <div className="page">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>
                    </div>

                    <div className="main-details-order">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <p>شناسه سفارش</p>
                                    </th>
                                    <th>
                                        <p> نام محصول</p>
                                    </th>
                                    <th>
                                        <p>تاریخ</p>
                                    </th>


                                    <th>
                                        <p>قیمت </p>
                                    </th>
                                    <th>
                                        <p>...</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((dtail) =>
                                    <tr>
                                        <td>
                                            {dtail.orderId}
                                        </td>
                                        <td>
                                            {dtail.productName}
                                        </td>
                                        <td>
                                            {dtail.date}
                                        </td>
                                        <td>
                                            {dtail.price}تومان
                                        </td>


                                        <td onClick={() => {
                                            setIsModalOpen({ status: true, type: "add" })
                                        }}>
                                            جزییات بیشتر
                                        </td>
                                    </tr>
                                )}


                            </tbody>
                        </table>
                    </div>
                    <div className="details-order-footer">
                        <span>نمایش ۱ تا {detialsTables.length} از {detialsTables.length} ورودی</span>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={detialsTables.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                </div>
            </div>

        </>


    )
}

export default Scores