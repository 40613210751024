import HeaderPage from "../components/main/layout/HeaderPage"
import ProgressBar from "@badrap/bar-of-progress";
import { discountTable as dt } from "../redux/slices/discountReducer";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DiscountsModal from 'src/components/modals/DiscountsModal'
import { Link } from "react-router-dom";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Discounts() {
    const discountTable = useSelector(dt)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })

    const [data, setData] = useState([...discountTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(discountTable.length / count)


    useEffect(() => {
        setData(discountTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, discountTable])

    useEffect(() => {
        setData(discountTable.slice((page - 1) * count, page * count))
    }, [pages, page, discountTable])


    useEffect(() => {
        setData(discountTable)
    }, [discountTable])
    return (
        <>
            <HeaderPage title={'تخفیف ها'} subtitle={'تخفیف ها'} route={{ name: "مالی", url: "/discounts" }} />
            {isModalOpen.status && <DiscountsModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="admin-discount">
                <div id="discount-section-one">
                    <div class="search-section">
                        <div class="search-box">
                            <div class="search-title">
                                <span>جستجو :</span>
                            </div>
                            <input type="search" />
                        </div>
                        <div class="show-items">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>
                    </div>
                    <div class="admin-discount-button">
                        <button>حذف</button>
                        <button>
                            <span>افزودن تخفیف</span>
                            <i class="fa-regular fa-plus fa-sm" style={{ color: " #ffffff;" }}></i>
                        </button>
                    </div>
                    <div class="discount-table">
                        <table class="admin-discount-table">
                            <thead>
                                <tr>
                                    <th>
                                        <div><input style={{ width: "16px", height: "16px; " }} type="checkbox" /></div>
                                    </th>
                                    <th>کد تخفیف</th>
                                    <th>تخفیف بر روی</th>
                                    <th>تاریخ شروع</th>
                                    <th>تاریخ انقضا</th>
                                    <th>مقدار تخفیف</th>
                                    <th><span>...</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((discount) =>
                                    <tr>
                                        <td>
                                            <div><input style={{ width: "16px", height: "16px; " }} type="checkbox" /></div>
                                        </td>
                                        <td>{discount.discountCode}</td>
                                        <td>{discount.discountOn}</td>
                                        <td>{discount.startDate}</td>
                                        <td>{discount.expirationDate}</td>
                                        <td>{discount.discountAmount}</td>
                                        <td onClick={() => {
                                            setIsModalOpen({ status: true, type: "add" })
                                        }}><a href="#"><i class="fa-regular fa-pen-to-square fa-lg"
                                            style={{ color: " #495057", fontSize: "20px" }}></i></a></td>
                                    </tr>

                                )}


                            </tbody>
                        </table>
                    </div>
                    <div class="discount-pagination-section">
                        <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={discountTable.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}
export default Discounts;