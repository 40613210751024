import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { cartTables as ct } from "src/redux/slices/shoppingCartReducer";
import { useSelector } from "react-redux";
import ShoppingCartModal from "src/components/modals/ShoppingCartModal"
import { useEffect, useState } from "react";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function ShoppingCart() {
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })
    const cartTables = useSelector(ct)
    const [data, setData] = useState([...cartTables])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(cartTables.length / count)



    useEffect(() => {
        setData(cartTables.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, cartTables])

    useEffect(() => {
        setData(cartTables.slice((page - 1) * count, page * count))
    }, [pages, page, cartTables])


    useEffect(() => {
        setData(cartTables)
    }, [cartTables])

    return (
        <>
            <HeaderPage title={"سبد خرید"} subtitle={"سبد خرید"} route={{ name: "سبد خرید", url: "/shopping-cart" }} />
            {isModalOpen.status && <ShoppingCartModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="shoppingCart-admin">
                <div id="cart-sec1">
                    <div className="search-bar">
                        <div className="search">
                            <label>
                                جستجو :
                                <input style={{ height: "36px;" }} />
                            </label>
                        </div>
                        <div className="show-page">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>
                    </div>

                    <div className="cart-table">
                        <table style={{ borderCollapse: "collapse", borderColor: "#EFF2F7", width: "100%" }}>
                            <thead style={{ height: "49px", backgroundColor: "#EFF2F7" }}>
                                <th>شناسه خرید</th>
                                <th id="customer-id">شناسه مشتری</th>
                                <th>نام</th>
                                <th>نام خانوادگی</th>
                                <th>تاریخ شروع</th>
                                <th>تاریخ پایان</th>
                                <th>مشاهده جزئیات</th>
                            </thead>


                            <tbody style={{ textAlign: "center", verticalAlign: "middle" }}>
                                {data.map((table) =>

                                    <tr>
                                        <td style={{ fontWeight: "700", direction: "ltr" }}>{table.purchaseId}</td>
                                        <td id="customer-id" style={{ fontWeight: "700" }}>{table.customerId}</td>
                                        <td>{table.name}</td>
                                        <td>{table.family}</td>
                                        <td>{table.startDate}</td>
                                        <td>{table.endDate}</td>
                                        <td onClick={() => {
                                            setIsModalOpen({ status: true, type: "add" })
                                        }} className="view-details">
                                            <Link to={"#"}>
                                                <span>مشاهده جزئیات</span>
                                            </Link>
                                        </td>
                                    </tr>

                                )}
                            </tbody>

                        </table>
                    </div>

                    <div className="under-table">
                        <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={cartTables.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>

                </div>
            </div>

        </>


    )
}

export default ShoppingCart