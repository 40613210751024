import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { couponTable as ct } from "src/redux/slices/couponsReducer";
import { useSelector } from "react-redux";
import CouponsModal from "src/components/modals/CouponsModal";
import { useEffect, useState } from "react";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AdminDashboard() {
    const couponTable = useSelector(ct)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })
    const [data, setData] = useState([...couponTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(couponTable.length / count)


    useEffect(() => {
        setData(couponTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, couponTable])

    useEffect(() => {
        setData(couponTable.slice((page - 1) * count, page * count))
    }, [pages, page, couponTable])


    useEffect(() => {
        setData(couponTable)
    }, [couponTable])
    return (
        <>
            <HeaderPage title={"کپن ها"} subtitle={"کپن ها"} route={{ name: "کپن ها", url: "/coupons" }} />
            {isModalOpen.status && <CouponsModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="kopons">
                <div className="kopon-head">
                    <div className="section-1">
                        <span>همه</span>
                        <p>فعال</p>
                        <p>غیر فعال</p>
                    </div>
                </div>
                <div className="kopon-search">
                    <div className="search">
                        <label>
                            جستجو:
                            <input type="search" />
                        </label>
                    </div>
                    <div className="page">
                        <label>
                            ورودی
                            <Select
                                defaultValue="10"
                                style={{
                                    width: 200,
                                }}
                                onChange={(value) => { setCount(value) }}
                                options={[
                                    // { value: 5, label: '5' },
                                    { value: 10, label: '10' },
                                    { value: 15, label: '15' },
                                    { value: 20, label: '20' },
                                ]}
                            />

                            نمایش
                        </label>
                    </div>
                </div>
                <div onClick={() => {
                    setIsModalOpen({ status: true, type: "add" })
                }} className="add-kopon">
                    <div className="add-1">

                        <span >افزودن کپن</span>
                        <i className="fa-sharp fa-solid fa-plus"></i>
                    </div>
                </div>
                <div className="main-kopon">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <p>شناسه</p>
                                </th>
                                <th>
                                    <p>نوع کپن</p>
                                </th>
                                <th>
                                    <p>مقدار</p>
                                </th>
                                <th>
                                    <p>تعداد استفاده</p>
                                </th>
                                <th>
                                    <span>تاریخ پایان</span>
                                    <p> کپن</p>

                                </th>
                                <th>
                                    <p>فعال/غیر فعال</p>
                                </th>
                                <th>
                                    <p>...</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((coupon) =>
                                <tr>
                                    <td>
                                        <p>{coupon.id}</p>
                                    </td>
                                    <td>
                                        <p>{coupon.couponType}</p>
                                    </td>
                                    <td>
                                        {coupon.couponType != "درصد" ? <p>{coupon.amount}تومان</p> : <p>{coupon.amount}%</p>}
                                    </td>
                                    <td>
                                        <p>{coupon.numberOfUse}</p>
                                    </td>
                                    <td>
                                        <p>{coupon.couponExpiration}</p>
                                    </td>
                                    <td><input type="checkbox" style={{ width: "16px", height: "16px" }} /></td>
                                    <td >
                                        <div>
                                            <p>مشاهده جزئیات</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div><i className="fa-light fa-circle-ellipsis"></i></div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="kopon-footer">
                    <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                    <Pagination
                        className="rtl-pagination"
                        current={page}
                        total={couponTable.length}
                        pageSize={count}
                        onChange={(page) => setPage(page)}
                    />
                </div>
            </div>

        </>
    )
}
export default AdminDashboard