import { createSlice } from '@reduxjs/toolkit'



export const commentSlice = createSlice({
    name: "comment",
    initialState: {
        comments: [
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
            {
                userName: "amir jafari", date: "۱۴۰۲/۰۸/۲۳", product: "شلوار مام فیت مردانه",
            },
        ]



    },
    reducers: {

    }

})

export const commentTable = (state) => state.comment.comments

export default commentSlice.reducer