import { Button, Modal, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { viewModals as vm } from 'src/redux/slices/viewChangesReducer';

function ViewChangesModal({ open, setOpen }) {
    const viewModals = useSelector(vm)
    const [data, setData] = useState([...viewModals])
    const [count, setCount] = useState(4)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(viewModals.length / count)


    useEffect(() => {
        setData(viewModals.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, viewModals])

    useEffect(() => {
        setData(viewModals.slice((page - 1) * count, page * count))
    }, [pages, page, viewModals])

    useEffect(() => {
        setData(viewModals)
    }, [viewModals])
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div className='changes-table-modal1'>
                    <div class="section-bala">
                        <span>جدول تغییرات</span>
                    </div>
                    <div id="changes-table-modal">

                        <div id="section-asli">
                            <div class="part-1">
                                <div class="search-bar">
                                    <div class="search">
                                        <span>جستجو :</span>
                                        <input type="search" />
                                    </div>
                                    <a href="#">جستجو</a>
                                </div>
                            </div>
                            <div class="part-table">
                                <table>
                                    <thead>

                                        <tr>
                                            <th>شناسه</th>
                                            <th>نام جدول</th>
                                            <th>تاریخ</th>
                                            <th>نام فیلد</th>
                                            <th>مقدار قدیم</th>
                                            <th>مقدار جدید</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((view) =>

                                            <tr>
                                                <td>{view.id}</td>
                                                <td>{view.tableTable}</td>
                                                <td>{view.date}</td>
                                                <td>{view.field}</td>
                                                <td>{view.oldAmount}تومان</td>
                                                <td>{view.newAmount}تومان</td>

                                            </tr>
                                        )}


                                    </tbody>
                                </table>
                            </div>
                            <div class="part-2">
                                <span>نمایش ۱ تا {viewModals.length} از {viewModals.length} ورودی</span>
                                <Pagination
                                    className="rtl-pagination"
                                    current={page}
                                    total={viewModals.length}
                                    pageSize={count}
                                    onChange={(page) => setPage(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </Modal >


        </>
    )
}

export default ViewChangesModal;