import HeaderPage from "../components/main/layout/HeaderPage"
import ProgressBar from "@badrap/bar-of-progress";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { productsTable as pt } from "src/redux/slices/addAdminReducer";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AdminProduct() {


    const productsTable = useSelector(pt)
    const [data, setData] = useState([...productsTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(productsTable.length / count)


    useEffect(() => {
        setData(productsTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, productsTable])

    useEffect(() => {
        setData(productsTable.slice((page - 1) * count, page * count))
    }, [pages, page, productsTable])


    useEffect(() => {
        setData(productsTable)
    }, [productsTable])
    return (
        <>
            <HeaderPage title={'افزودن محصولات'} subtitle={'محصولات'} route={{ name: "محصولات", url: "/admin-product" }} />

            <div id="admin-products">

                <div id="section1-products">


                    <div className="ul-section">
                        <ul>
                            <li className="first-option">
                                <a href="#">همه</a>
                            </li>
                            <li>
                                <a href="#">موجود</a>
                            </li>
                            <li>
                                <a href="#">ناموجود</a>
                            </li>
                        </ul>
                    </div>

                    <div className="search-section">
                        <div className="row1">

                            <div className="box-search">
                                <div className="box-span">
                                    <span>جستجو :</span>
                                </div>
                                <div className="box-input">
                                    <input type="search" placeholder="" />
                                </div>
                            </div>

                            <div className="box-select1">

                                <div className="box-span">
                                    <span> دسته بندی:</span>
                                </div>
                                <div className="select">
                                    <select>
                                        <option>پیراهن</option>
                                        <option>پیراهن</option>
                                        <option>پیراهن</option>
                                        <option>پیراهن</option>
                                    </select>
                                </div>

                            </div>
                        </div>



                        <div className="row2">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>
                    </div>


                    <div className="box-select2">


                        <div className="select">
                            <select>
                                <option>پیراهن</option>
                                <option>پیراهن</option>
                                <option>پیراهن</option>
                                <option>پیراهن</option>
                            </select>
                        </div>

                    </div>
                    <div className="table-section">

                        <table className="product-table">
                            <thead>
                                <tr>
                                    <th>نام محصول</th>
                                    <th>قیمت</th>
                                    <th>دسته بندی</th>
                                    <th>وضعیت موجودی</th>
                                    <th>تعداد موجودی</th>
                                    <th>...</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data.map((product) =>
                                    <tr className="odd">
                                        <td>{product.productName}</td>
                                        <td>{product.price}تومان</td>
                                        <td>{product.category}</td>

                                        <td className="box-status">
                                            {product.existance != true ? <div className="status-color-r">
                                                <span>ناموجود</span>
                                            </div> : <div className="status-color-g">
                                                <span>موجود</span>
                                            </div>}
                                        </td>
                                        <td>{product.amount}</td>
                                        <td className="edit">
                                            <a href="#">
                                                <i className="fa-regular fa-pen-to-square"></i>
                                            </a>
                                        </td>
                                    </tr>

                                )}
                            </tbody>
                        </table>


                        <div className="show-table-pages">
                            <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                            <Pagination
                                className="rtl-pagination"
                                current={page}
                                total={productsTable.length}
                                pageSize={count}
                                onChange={(page) => setPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </div>





        </>
    )
}
export default AdminProduct;