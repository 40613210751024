import { Menu, Button } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const { SubMenu } = Menu;

export default function Accordion() {
  const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5"];
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (_openKeys) => {
    const latestOpenKey = _openKeys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(_openKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const dispatch = useDispatch();

  return (
    <div style={{ width: "100%" }}>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        theme="transparent"
        inlineCollapsed={false}
      >
        <Menu.Item key="1">
          {/* <Icon type="pie-chart" /> */}
          <i className="fa-light fa-house-chimney fa-l"></i>
          <Link to={"/admin-dashboard"}>پیشخوان</Link>
        </Menu.Item>

        <Menu.Item key="2">
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-wallet "></i>
          <Link to={"/add-financial"}>کیف پول</Link>
        </Menu.Item>

        <SubMenu
          key="sub1"
          title={
            <span>
              {/* <Icon type="mail" /> */}
              <span>دسته بندی ها</span>
            </span>
          }
        >
          <Menu.Item key="3">
            <Link to={"/categories"}>دسته بندی ها</Link>
          </Menu.Item>

          <Menu.Item key="4">
            <Link to={"/add-category"}>افزودن دسته بندی ها</Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="5">
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-message-lines "></i>
          <Link to={"/comment"}>نظرات</Link>
        </Menu.Item>

        <Menu.Item key="6" >
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-location-dot"></i>
          <Link to={"/add-address"}>افزودن آدرس</Link>
        </Menu.Item>

        <Menu.Item key="7">
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-cart-shopping"></i>
          <Link to="/shopping-cart">سبد خرید</Link>
        </Menu.Item>

        <Menu.Item key="8" >
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-money-check-pen"></i>
          <Link to="/view-changes">مشاهده تغییرات</Link>
        </Menu.Item>

        <Menu.Item key="9">
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-hundred-points"></i>
          <Link to="/scores">جدول امتیازات</Link>
        </Menu.Item>

        <SubMenu
          key="sub2"
          title={
            <span>
              {/* <Icon type="appstore" /> */}
              <span>مدیران</span>
            </span>
          }
        >
          <Menu.Item key="10">
            <Link to="/managers">مدیران</Link>
          </Menu.Item>

          <Menu.Item key="11">
            <Link to="/add-managers">افزودن مدیران</Link>
          </Menu.Item>

        </SubMenu>

        <SubMenu
          key="sub3"
          title={
            <span>
              {/* <Icon type="appstore" /> */}
              <span>محصولات</span>
            </span>
          }
        >
          <Menu.Item key="12" >
            <Link to="/admin-product">محصولات</Link>
          </Menu.Item>

          <Menu.Item key="13">
            <Link to="/add-product">افزودن محصول</Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="14">
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-people "></i>
          <Link to="/customers">مشتریان</Link>
        </Menu.Item>

        <Menu.Item key="15">
          {/* <Icon type="desktop" /> */}
          <i className="fa-light fa-table-list"></i>
          <Link to="/orders">سفارشات</Link>
        </Menu.Item>

        <SubMenu
          key="sub4"
          title={
            <span>
              {/* <Icon type="appstore" /> */}
              <span>ویژگی ها</span>
            </span>
          }
        >
          <Menu.Item key="16">
            <Link to="/properties">ویژگی ها</Link>
          </Menu.Item>

          <Menu.Item key="17">
            <Link to="/add-feature">افزودن ویژگی</Link>
          </Menu.Item>

        </SubMenu>

        <SubMenu
          key="sub5"
          title={
            <span>
              {/* <Icon type="appstore" /> */}
              <span>کپن و تخفیفات</span>
            </span>
          }
        >
          <Menu.Item key="18">
            <Link to="/discounts">تخفیف ها</Link>
          </Menu.Item>

          <Menu.Item key="19">
            <Link to="/add-discount">افزودن تخفیف</Link>
          </Menu.Item>

          <Menu.Item key="20">
            <Link to="/coupons">کپن ها</Link>
          </Menu.Item>

          <Menu.Item key="21">
            <Link to="/add-coupon">افزودن کپن</Link>
          </Menu.Item>

        </SubMenu>
      </Menu>
    </div>
  );
}

{
  /* <SubMenu
  key="sub2"
  title={
    <span>
      {/* <Icon type="appstore" /> */
}
//       <span>مدیران</span>
//     </span>
//   }
// >
//   <Menu.Item key="9">Option 9</Menu.Item>
//   <Menu.Item key="10">Option 10</Menu.Item>
//   <SubMenu key="sub3" title="Submenu">
//     <Menu.Item key="11">Option 11</Menu.Item>
//     <Menu.Item key="12">Option 12</Menu.Item>
//   </SubMenu>
// </SubMenu> */}
