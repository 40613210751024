import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

function ManagersModal({ open, setOpen }) {
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div className="section-bala">
                    <span>جزئیات اطلاعات مدیر</span>
                    {/* <i className="fa-solid fa-xmark fa-xl"></i> */}
                </div>
                <div id="admin-info-detail-modal">

                    <div className="section-paiin">
                        <div className="sec-1">
                            <div className="frame">
                                <div className="part-bala">
                                    <span>نام :</span>
                                    <span>محمد</span>
                                </div>
                                <div className="part-paiin">
                                    <span>نام خانوادگی :</span>
                                    <span>مهدوی</span>
                                </div>
                            </div>
                            <div className="frame">
                                <div className="part-bala">
                                    <span>جنسیت:</span>
                                    <span>مرد</span>
                                </div>
                                <div className="part-paiin">
                                    <span>کد ملی:</span>
                                    <span>۲۷۲۱۷۴۶۸۵۲</span>
                                </div>
                            </div>
                        </div>
                        <div className="sec-2">
                            <div className="frame">
                                <div className="part-bala">
                                    <span>تاریخ تولد:</span>
                                    <span>۱۳۷۸/۱/۱۵</span>
                                </div>
                                <div className="part-paiin">
                                    <span>سن:</span>
                                    <span>۲۴</span>
                                </div>
                            </div>
                            <div className="frame">
                                <div className="part-bala">
                                    <span>شغل:</span>
                                    <span>کارمند</span>
                                </div>
                                <div className="part-paiin">
                                    <span >شماره تلفن:</span>
                                    <span>۰۹۰۱۵۱۳۷۵۶۲</span>
                                </div>
                            </div>
                        </div>
                        <div className="sec-3">
                            <div className="frame-2">
                                <div className="part-bala">
                                    <span>رمز:</span>
                                    <span>jdh45#kj22</span>
                                </div>
                                <div className="part-paiin">
                                    <span>ایمیل:</span>
                                    <span>mehdinikzad@gmail.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="sec-4">
                            <div className="frame-2">
                                <div className="part-bala">
                                    <span>آدرس:</span>
                                </div>
                                <div className="part-paiin">
                                    <span>لاهیجان - میدان انقلاب - خیابان فیاض-پاساژ ظروفی</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Modal >


        </>
    )
}

export default ManagersModal;