import { createSlice } from "@reduxjs/toolkit";


export const couponsSlice = createSlice({
    name: "coupons",
    initialState: {
        couponsTable: [
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "تومان", amount: "۱۵۰۰۰۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "تومان", amount: "۱۵۰۰۰۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "تومان", amount: "۱۵۰۰۰۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "تومان", amount: "۱۵۰۰۰۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
            { id: "#SK2540", couponType: "درصد", amount: "۱۰", numberOfUse: "۱", couponExpiration: "۱۴۰۲/۰۲/۳۰" },
        ],
        couponsModal: [
            { id: 564891336, name: "امیرحسین", family: "جعفری", nationalCode: 248364566 },
            { id: 564891336, name: "امیرحسین", family: "جعفری", nationalCode: 248364566 },
            { id: 564891336, name: "امیرحسین", family: "جعفری", nationalCode: 248364566 },
            { id: 564891336, name: "امیرحسین", family: "جعفری", nationalCode: 248364566 },
            { id: 564891336, name: "امیرحسین", family: "جعفری", nationalCode: 248364566 },
            { id: 564891336, name: "امیرحسین", family: "جعفری", nationalCode: 248364566 },
            { id: 564891336, name: "امیرحسین", family: "جعفری", nationalCode: 248364566 },
        ]
    },
    reducers: {

    }
})


export const couponTable = (state) => state.coupons.couponsTable
export const couponsModal = (state) => state.coupons.couponsModal
export default couponsSlice.reducer