import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

function OrderModal({ open, setOpen }) {
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div className="section-bala">
                    <span>جزئیات سفارش</span>
                    {/* <i className="fa-solid fa-xmark fa-xl"></i> */}
                </div>
                <div id="order-detail-modal">

                    <div className="section-paiin">
                        <div className="section-img">
                            <img src={process.env.PUBLIC_URL + "assets/images/Frame 6624.png"} width="100%" height="100%" />
                        </div>
                        <div className="section-asli">
                            <div className="sec-right">
                                <span>شلوار جین مشکی</span>
                                <span>قیمت محصول : ۷۸۰ هزار تومان</span>
                                <span>تعداد :  ۱</span>
                                <div className="spans">
                                    <span className="aval">کپن تخفیف :  #SK45026</span>
                                    <span className="dovom">۲۰% تخفیف</span>
                                </div>
                            </div>
                            <div className="sec-left">
                                <span>سایز :۴۶</span>
                                <div className="color">
                                    <div className="dayere"></div>
                                    <span>مشکی</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal >


        </>
    )
}

export default OrderModal;