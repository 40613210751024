import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

function PropertiesModal({ open, setOpen }) {
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div class="sec1">
                    <span>مقادیر وارد شده</span>
                    {/* <i class="fa-solid fa-xmark fa-xl"></i> */}
                </div>
                <div id="properties-modal">

                    <div class="sec2">
                        <div class="colors">
                            <div class="color">
                                <div></div>
                                <span>02005F</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>FFFFFF</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>000000</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>قرمز</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>FFFFFF</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>02005F</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>طوسی</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>000000</span>
                            </div>
                            <div class="color">
                                <div></div>
                                <span>قرمز</span>
                            </div>
                        </div>

                    </div>
                </div>


            </Modal >


        </>
    )
}

export default PropertiesModal;