import { createSlice } from "@reduxjs/toolkit";

export const scoresSlice = createSlice({
    name: "scores",
    initialState: {
        scoresTable: [],


    },
    reducers: {
        load: (state, action) => {
            state.scoresTable = action.payload
        }
    }
})
export const { load } = scoresSlice.actions
export const scoreTable = (state) => state.scores.scoresTable
export default scoresSlice.reducer