import { combineSlices, configureStore } from "@reduxjs/toolkit"
import adminDashboardReducer from "src/redux/slices/adminDashboardReducer"
import addFinancialReducer from "./slices/addFinancialReducer"
import shoppingCartReducer from "./slices/shoppingCartReducer"
import viewChangesReducer from "./slices/viewChangesReducer"
import scoresReducer from "./slices/scoresReducer"
import commentReducer from "./slices/commentReducer"
import managerReducer from "./slices/managerReducer"
import addManagersReducer from "./slices/addManagerReducer"
import addAdminReducer from "./slices/addAdminReducer"
import customerReduce from "./slices/customerReducecr"
import ordersReducer from './slices/ordersReducer'
import propertiesReducer from './slices/propertiesReducer'
import discountReducer from './slices/discountReducer'
import couponsReducer from "./slices/couponsReducer"
import managerdataReducer from "./slices/managerDataReducer"
import categoryReducer from "src/redux/slices/categoryReducer"
import detailsOrderReducer from "./slices/detailsOrderReducer"
// const rootReducer = combineSlices({ managerData: managerdataReducer, viewChanges: viewChangesReducer })
export default configureStore({
    reducer: {
        addFinancial: addFinancialReducer,
        adminDashboard: adminDashboardReducer,
        shoppingCart: shoppingCartReducer,
        viewChanges: viewChangesReducer,
        scores: scoresReducer,
        comment: commentReducer,
        manager: managerReducer,
        addManager: addManagersReducer,
        addAdmin: addAdminReducer,
        customer: customerReduce,
        orders: ordersReducer,
        properties: propertiesReducer,
        discount: discountReducer,
        coupons: couponsReducer,
        managerData: managerdataReducer,
        categories: categoryReducer,
        detailsOrder: detailsOrderReducer,
        // combines: rootReducer,
    }
})