import { createSlice } from "@reduxjs/toolkit";


export const addAdminSlice = createSlice({
    name: "addAdmin",
    initialState: {
        productTable: [
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: false, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: false, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: false, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: false, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: false, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: false, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: false, amount: 0 },
            { productName: "شلوار مردانه", price: "۴۳۰.۰۰۰", category: 'شلوار', existance: true, amount: 0 },
        ]


    },
    reducers: {


    }
})

export const productsTable = (state) => state.addAdmin.productTable

export default addAdminSlice.reducer