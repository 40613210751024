import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'


function Nav() {

    return (
        <>
            <nav id="nav-admin">
                <div className="logo-section">
                    <div className="logo">
                        <img src={process.env.PUBLIC_URL + '/assets/images/logo-fashion.png'} />
                    </div>

                </div>
                <div className="header">

                    <div className="section1">
                        <div className="button">
                            <a href="#">
                                <i className="fa-light fa-bars fa-xl"></i>
                            </a>
                        </div>
                    </div>

                    <div className="section2">

                        <div className="bell-icon">

                            <a href="#">
                                <i className="fa-light fa-bell fa-xl"></i>
                            </a>

                        </div>
                        <div className="profile">

                            <div className="dropdown">
                                <button className="dropbtn">
                                    <img src={process.env.PUBLIC_URL + './assets/images/avatar-1.jpg.png'} />
                                    <span>امیر</span>
                                    <i className="fa-solid fa-angle-down"></i>
                                </button>
                                <div className="dropdown-content">
                                    <Link to={"/admin-login"}>login</Link>
                                    <a href="#">Link 2</a>
                                    <a href="#">Link 3</a>
                                </div>
                            </div>
                        </div>

                        <div className="setting-icon">

                            <a href="#">
                                <i className="fa-light fa-gear fa-xl"></i>
                            </a>
                        </div>

                    </div>
                </div>
            </nav>

        </>
    )
}

export default Nav;