import { Link } from "react-router-dom";
import Accordion from 'src/components/main/layout/Accordion'
function Sidebar() {
    return (
        <>


            {false && <div id="sidebar-admin">

                <ul>

                    <li className="title">مدیریت</li>

                    <button className="accordion1">
                        <i className="fa-light fa-house-chimney fa-l"></i>
                        <span><Link to={"/admin-dashboard"}>پیشخوان</Link></span>
                    </button>

                    <button className="accordion1">
                        <i className="fa-light fa-wallet "></i>
                        <span>
                            <Link to={"/add-financial"}>کیف پول</Link>
                        </span>
                    </button>


                    <li className="title">صفحات</li>


                    <button className="accordion">
                        <div className="box-text">
                            <i className="fa-sharp fa-light fa-grid-4 "></i>
                            <span>
                                <Link to={"#"}>دسته بندی ها</Link>
                            </span>
                        </div>
                        <i className="fa-light fa-angle-down"></i>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to={"./Pages/categories.html"}>دسته بندی ها</Link>
                            </li>
                            <li>
                                <Link to={"./Pages/afzodan-dastebandi.html"}>افزودن دسته بندی ها</Link>
                            </li>
                        </ul>
                    </div>


                    <button className="accordion1">
                        <i className="fa-light fa-message-lines "></i>
                        <span>
                            <Link to={"/comment"}>نظرات</Link>
                        </span>
                    </button>

                    <button className="accordion1">
                        <i className="fa-light fa-message-lines "></i>
                        <span>
                            <Link to={"/details-order"}>جزئیات سفارش</Link>
                        </span>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to={""}>test</Link>
                            </li>
                            <li>
                                <Link to={""}>test</Link>
                            </li>
                            <li>
                                <Link to={""}>test</Link>
                            </li>
                        </ul>
                    </div>

                    <button className="accordion1">
                        <i className="fa-light fa-location-dot"></i>
                        <span>
                            <Link to={"/add-address"}>افزودن آدرس</Link>
                        </span>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to={""}>test</Link>
                            </li>
                            <li>
                                <Link to={""}>test</Link>
                            </li>
                            <li>
                                <Link to={""}>test</Link>
                            </li>
                        </ul>
                    </div>


                    <button className="accordion1">
                        <i className="fa-light fa-cart-shopping"></i>
                        <span>
                            <Link to="/shopping-cart">سبد خرید</Link>
                        </span>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                        </ul>
                    </div>

                    <button className="accordion1">
                        <i className="fa-light fa-money-check-pen"></i>
                        <span>
                            <Link to="/view-changes">مشاهده تغییرات</Link>
                        </span>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                        </ul>
                    </div>

                    <button className="accordion1">
                        <i className="fa-light fa-hundred-points"></i>
                        <span>
                            <Link to="/scores">جدول امتیازات</Link>
                        </span>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                        </ul>
                    </div>

                    <button className="accordion">

                        <div className="box-text">
                            <i className="fa-light fa-user-group "></i>
                            <span>
                                <Link to="#">مدیران</Link>
                            </span>
                        </div>

                        <i className="fa-light fa-angle-down"></i>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="./Pages/modiran.html">مدیران</Link>
                            </li>
                            <li>
                                <Link to="./Pages/Add-managers.html">افزودن مدیران</Link>
                            </li>
                        </ul>
                    </div>
                    <li className="title">فروشگاه</li>

                    <button className="accordion">

                        <div className="box-text">
                            <i className="fa-light fa-store"></i>
                            <span>
                                <Link to="#">محصولات</Link>
                            </span>
                        </div>
                        <i className="fa-light fa-angle-down"></i>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="./Pages/admin-products.html">محصولات</Link>
                            </li>
                            <li>
                                <Link to="./Pages/add-product.html">افزودن محصول</Link>
                            </li>
                        </ul>
                    </div>


                    <button className="accordion1">
                        <i className="fa-light fa-people "></i>
                        <span>
                            <Link to="./Pages/customers.html">مشتریان</Link>
                        </span>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                        </ul>
                    </div>
                    <button className="accordion1">
                        <i className="fa-light fa-table-list"></i>
                        <span>
                            <Link to="/orders">سفارشات</Link>
                        </span>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                            <li>
                                <Link to="">test</Link>
                            </li>
                        </ul>
                    </div>



                    <button className="accordion">

                        <div className="box-text">
                            <i className="fa-sharp fa-light fa-circles-overlap"></i>
                            <span>
                                <Link to="#">ویژگی ها</Link>
                            </span>

                        </div>

                        <i className="fa-light fa-angle-down"></i>
                    </button>

                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="./Pages/properties.html">ویژگی ها</Link>
                            </li>
                            <li>
                                <Link to="./Pages/add-feature.html">افزودن ویژگی</Link>
                            </li>
                        </ul>
                    </div>




                    <button className="accordion">

                        <div className="box-text">
                            <i className="fa-sharp fa-light fa-badge-percent"></i>
                            <span>
                                <Link to="#">کپن و تخفیفات</Link>
                            </span>

                        </div>

                        <i className="fa-light fa-angle-down"></i>
                    </button>
                    <div className="panel">
                        <ul>
                            <li>
                                <Link to="./Pages/discounts.html">تخفیف ها</Link>
                            </li>
                            <li>
                                <Link to="./Pages/add-discount.html">افزودن تخفیف</Link>
                            </li>
                            <li>
                                <Link to="./Pages/kopens.html">کپن ها</Link>
                            </li>
                            <li>
                                <Link to="./Pages/addKopon.html">افزودن کپن</Link>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>}


            <div id="sidebar-admin">
                <Accordion />
            </div>




        </>
    )
}
export default Sidebar;