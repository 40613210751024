import { Button, Modal, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { couponsModal as cm } from 'src/redux/slices/couponsReducer';

function CouponsModal({ open, setOpen }) {
    const couponsModal = useSelector(cm)
    console.log(couponsModal)
    const [data, setData] = useState([...couponsModal])
    const [count, setCount] = useState(4)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(couponsModal.length / count)


    useEffect(() => {
        setData(couponsModal.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, couponsModal])

    useEffect(() => {
        setData(couponsModal.slice((page - 1) * count, page * count))
    }, [pages, page, couponsModal])

    useEffect(() => {
        setData(couponsModal)
    }, [couponsModal])
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div id="kopon-modal">
                    <div className="section-bala">
                        <div className="spans">
                            <span>انتخاب مشتری</span>
                            <span>شناسه : #SK2540</span>
                        </div>
                        {/* <i className="fa-solid fa-xmark fa-xl"></i> */}
                    </div>
                    <div id="section-asli">
                        <div className="part-1">
                            <div className="search-bar">
                                <div className="search">
                                    <span>جستجو :</span>
                                    <input type="search" />
                                </div>
                                <a href="#">جستجو</a>
                            </div>
                            <a href="#">ثبت</a>
                        </div>
                        <div className="part-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th><input style={{ width: "16px", height: "16px" }} type="checkbox" /></th>
                                        <th>شناسه کاربری</th>
                                        <th>نام</th>
                                        <th>نام خانوادگی</th>
                                        <th>کد ملی</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((coupons) =>
                                        <tr>
                                            <td><input style={{ width: "16px", height: "16px" }} type="checkbox" /></td>
                                            <td>{coupons.id}</td>
                                            <td>{coupons.name}</td>
                                            <td>{coupons.family}</td>
                                            <td>{coupons.nationalCode}</td>
                                        </tr>

                                    )}


                                </tbody>
                            </table>
                        </div>
                        <div className="part-2">
                            <span>نمایش ۱ تا {couponsModal.length} از {couponsModal.length} ورودی</span>
                            <Pagination
                                className="rtl-pagination"
                                current={page}
                                total={couponsModal.length}
                                pageSize={count}
                                onChange={(page) => setPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </Modal >


        </>
    )
}

export default CouponsModal;