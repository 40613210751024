import { createSlice } from "@reduxjs/toolkit"

export const detailsOrdersSlice = createSlice({
    name: "detailsOrder",
    initialState: {
        detialsTable: []
    },
    reducers: {
        load: (state, action) => {
            state.detialsTable = action.payload
        }
    }
})
export const { load } = detailsOrdersSlice.actions
export const detialsTables = (state) => state.detailsOrder.detialsTable


export default detailsOrdersSlice.reducer