import HeaderPage from "../components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}


function AddFeature() {
    return (

        <>
            <HeaderPage title={'افزودن ویژگی'} subtitle={'ویژگی'} route={{ name: "ویژگی", url: "/add-feature" }} />

            <form>
                <div id="feature">
                    <div className="topic">
                        <p>عنوان ویژگی</p>
                        <input type="text" placeholder="عنوان ویژگی را وارد کنید" id="tital" />
                    </div>
                    <div className="category">
                        <p>انتخاب دسته بندی</p>
                        <div className="select">
                            <p>multi select</p>
                        </div>
                    </div>
                </div>

                <div className="save">
                    <div className="save-1">
                        <a href="#"><span>ذخیره</span></a>
                    </div>
                    <div className="delete">
                        <a href="#"><span>حذف</span></a>

                    </div>
                </div>
            </form>


        </>
    )
}

export default AddFeature;