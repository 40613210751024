import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { managersTable as mt } from "src/redux/slices/managerReducer";
import { useSelector } from "react-redux";
import ManagersModal from 'src/components/modals/ManagersModal'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Managers() {
    const managersTable = useSelector(mt)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })

    const [data, setData] = useState([...managersTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(managersTable.length / count)



    useEffect(() => {
        setData(managersTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, managersTable])

    useEffect(() => {
        setData(managersTable.slice((page - 1) * count, page * count))
    }, [pages, page, managersTable])


    useEffect(() => {
        setData(managersTable)
    }, [managersTable])
    return (
        <>

            <HeaderPage title={"مدیران"} subtitle={"مدیران"} route={{ name: "مدیران", url: "/managers" }} />
            {isModalOpen.status && <ManagersModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="modiran">
                <div className="search">
                    <div className="right">
                        <label for="">

                            <h6>جستجو</h6>
                            <input type="text" />
                        </label>
                    </div>
                    <div className="left">
                        <label>
                            ورودی
                            <Select
                                defaultValue="10"
                                style={{
                                    width: 200,
                                }}
                                onChange={(value) => { setCount(value) }}
                                options={[
                                    // { value: 5, label: '5' },
                                    { value: 10, label: '10' },
                                    { value: 15, label: '15' },
                                    { value: 20, label: '20' },
                                ]}
                            />

                            نمایش
                        </label>
                    </div>
                </div>
                <div className="buttons">
                    <div className="add">
                        <a href="./add-managers">افزودن مدیران +</a>
                    </div>
                </div>
                <div className="modiran-table">
                    <table>
                        <thead>
                            <tr>
                                <th><span>شناسه</span></th>
                                <th><span>نام کاربری</span></th>
                                <th><span>کد ملی</span></th>
                                <th><span>شماره موبایل</span></th>
                                <th><span>فعال</span></th>
                                <th><span>غیرفعال</span></th>
                                <th><span>...</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((manager) =>
                                <tr>
                                    <td><span>{manager.id}</span></td>
                                    <td><span>{manager.userName}</span></td>
                                    <td><span>{manager.nationalCode}</span></td>
                                    <td><span>{manager.phone}</span></td>
                                    <td> <input type="radio" name="d1" /> </td>
                                    <td><input type="radio" name="d1" /></td>
                                    <td onClick={() => {
                                        setIsModalOpen({ status: true, type: "add" })
                                    }}><a href="#">مشاهده بیشتر</a></td>

                                </tr>
                            )}
                        </tbody>




                    </table>
                </div>
                <div className="pagination-bottom">
                    <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                    <Pagination
                        className="rtl-pagination"
                        current={page}
                        total={managersTable.length}
                        pageSize={count}
                        onChange={(page) => setPage(page)}
                    />
                </div>
            </div>

        </>
    )
}

export default Managers;