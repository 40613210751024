import HeaderPage from "../components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AddCoupon() {
    return (
        <>
            <HeaderPage title={'افزودن کپن'} subtitle={'کپن'} route={{ name: "کپن", url: "/add-kopon" }} />


            <div id="coupn">
                <div class="mohtava">
                    <div>
                        <div>
                            <h5>نوع کپن </h5>
                            <select name="coupn">
                                <option value="1">نوع کپن را وارد کنید</option>
                                <option value="2">درصدی</option>
                                <option value="3">تومان</option>
                            </select>
                        </div>
                        <div>
                            <h5>مقدار</h5>
                            <input type="text" placeholder="مقدار کپن را وارد کنید" />
                        </div>
                        <div>
                            <h5>تاریخ پایان کپن</h5>
                            <input type="text" placeholder="انتخاب تاریخ پایان کپن" />
                        </div>
                    </div>
                    <div>

                        <div>
                            <h5>تعداد دفعات استفاده</h5>
                            <input type="text" placeholder="دفعات استفاده را وارد کنید" />
                        </div>
                        <div>
                            <h5>محصولات(Multi select)</h5>
                            <select name="coupn">
                                <option value="1">Multi select</option>
                                <option value="1">محصولات</option>
                                <option value="2">مولتی سلکت</option>

                            </select>
                        </div>
                        <div class="radio">
                            <h5>وضعیت کپن</h5>
                            <div class="except">

                                <div>
                                    <h5>فعال</h5>
                                    <input type="radio" name="radio" />
                                </div>
                                <div>
                                    <h5>غیر فعال</h5>
                                    <input type="radio" name="radio" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="zakhire">
                    <div><a href="#">ذخیره</a></div>
                    <div><a href="#">حذف</a></div>
                </div>
            </div>
        </>
    )
}
export default AddCoupon;