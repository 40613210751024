import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AddDiscount() {
    return (
        <>
            <HeaderPage title={"تخفیف ها"} subtitle={"تخفیف ها"} route={{ name: "تخفیف ها", url: "/add-discount" }} />
            
            <div id="admin-add-discount">
                <div id="section-1">
                    <div className="bakhsh-bala">
                        <div className="bakhsh-right">
                            <div className="field">
                                <div className="title-field">
                                    <span>نوع تخفیف</span>
                                </div>
                                <div className="select-field">
                                    <select>
                                        <option selected>نوع تخفیف را انتخاب کنید</option>
                                        <option>درصد</option>
                                        <option>تومان</option>
                                    </select>
                                </div>
                            </div>
                            <div className="field">
                                <div className="title-field">
                                    <span>مقدار</span>
                                </div>
                                <div className="select-field">
                                    <input type="text" placeholder="مقدار تخفیف را وارد کنید" />
                                </div>
                            </div>
                            <div className="field">
                                <div className="title-field">
                                    <span>تاریخ شروع تخفیف</span>
                                </div>
                                <div className="select-field">
                                    <input type="text" placeholder="انتخاب تاریخ شروع تخفیف" />
                                </div>
                            </div>
                            <div className="field">
                                <div className="title-field">
                                    <span>تاریخ پایان تخفیف</span>
                                </div>
                                <div className="select-field">
                                    <input type="text" placeholder="انتخاب تاریخ پایان تخفیف" />
                                </div>
                            </div>
                        </div>
                        <div className="bakhsh-left">
                            <div className="field">
                                <div className="title-field">
                                    <span>تخفیف بر روی</span>
                                </div>
                                <div className="select-field">
                                    <select>
                                        <option selected>تخفیف بر روی محصول / برند را انتخاب کنید </option>
                                        <option>درصد</option>
                                        <option>تومان</option>
                                    </select>
                                </div>
                            </div>
                            <div className="field">
                                <div className="title-field">
                                    <span>محصولات مورد نظر(multi select)</span>
                                </div>
                                <div className="select-field">
                                    <select>
                                        <div className="multiselect"></div>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="section-2">
                <Link to={""}>ذخیره</Link>
                <Link to={""} style={{ backgroundColor: " #556EE6" }}>حذف</Link>
            </div>

        </>


    )
}

export default AddDiscount