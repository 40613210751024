import { createSlice } from "@reduxjs/toolkit";


export const properiesSlice = createSlice({
    name: "properties",
    initialState: {
        properiesTable: [
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },
            { id: "#SK2540", properiesTitle: "جنس", category: "شلوار" },

        ]
    },
    reducers: {


    }
})

export const propertyTable = (state) => state.properties.properiesTable
export default properiesSlice.reducer