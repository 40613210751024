import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

function CommentModal({ open, setOpen }) {
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div className="section-head">
                    <span>جزئیات نظر</span>
                    {/* <i className="fa-solid fa-xmark fa-lg"></i> */}
                </div>

                <div id="comment-modal-details">

                    <div className="section-1">
                        <span>پیراهن آستین کوتاه پسرانه</span>
                        <div className="icons">
                            <div className="icon-right">
                                <span>۴</span>
                                <i className="fa-light fa-thumbs-down fa-lg"></i>
                            </div>
                            <div className="icon-left">
                                <span>۱۲۸</span>
                                <i className="fa-light fa-thumbs-up fa-lg"></i>
                            </div>
                        </div>
                    </div>
                    <div className="section-2">
                        <div className="right">
                            <span>نام کاربری :</span>
                            <span style={{ color: "#495057", fontWeight: "400" }}>mehdi nikzad</span>
                        </div>
                        <div className="left">
                            <span>تاریخ :</span>
                            <span style={{ color: "#495057", fontWeight: "400" }}>۱۴۰۲/۰۸/۱۲</span>
                        </div>
                    </div>
                    <div className="section-main">
                        <span>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و
                            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد می توان امید داشت که تمام و دشواری موجود در ارائه
                            راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
                            پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.</span>
                    </div>
                    <div className="section-foot">
                        <Link to="#">تایید</Link>
                        <Link to="#" style={{ backgroundColor: "#556EE6" }} onClick={(() => {
                            setOpen(false)
                        })}>عدم تایید</Link>
                    </div>
                </div>


            </Modal >


        </>
    )
}

export default CommentModal;