import { createSlice } from "@reduxjs/toolkit";

export const discountSlice = createSlice({
    name: "discount",
    initialState: {
        discountTable: [
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۰%" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۵۰۰۰۰ تومان" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۰%" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۵۰۰۰۰ تومان" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۰%" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۵۰۰۰۰ تومان" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۰%" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۵۰۰۰۰ تومان" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۰%" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۵۰۰۰۰ تومان" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۰%" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۵۰۰۰۰ تومان" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۰%" },
            { discountCode: "vahshatnak", discountOn: "شلوار", startDate: "۱۴۰۲/۰۲/۱۵", expirationDate: "۱۴۰۲/۰۲/۳۰", discountAmount: "۱۵۰۰۰۰ تومان" },
        ]

    },
    reducers: {

    }
})

export const discountTable = (state) => state.discount.discountTable
export default discountSlice.reducer