import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

function AddPropertiesModal({ open, setOpen }) {
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div className="sec1">
                    <span>مقدار ویژگی</span>
                    {/* <i className="fa-solid fa-xmark fa-xl"></i> */}
                </div>
                <div id="properties-modal">
                    <input style={{ height: "36px" }} className="sec2" placeholder="مقادیر ویژگی مورد نظر را وارد کنید" />
                    <div className="sec3">
                        <span onClick={() => {
                            setOpen(false)
                        }} className="delete">حذف</span>
                        <span className="accept">ثبت</span>
                    </div>
                </div>

            </Modal >


        </>
    )
}

export default AddPropertiesModal;