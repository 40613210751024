import { createSlice } from "@reduxjs/toolkit";
import Store from 'src/redux/Store'
import viewChangesReducer from "./viewChangesReducer";
import { useDispatch } from 'react-redux';


export const managerDataSlice = createSlice({
    name: 'managerData',
    initialState: {
        viewTables: [
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
        ],
        scoresTable: [
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
            {
                scoreId: "#kd56", score: 3.5, date: "1402/02/05",
                costumerName: "امیرحسین جعفری", productTitle: "شلوار"
            },
        ],
        detailsOrders: [
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
            { id: "#SK2540 ", tableName: "لباس", admin: "امیرحسین جعفری", date: "02/02/225" },
        ],
        detialsTable: [
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },

        ],
        viewModal: [
            { id: "#25402", tableTable: "جدول محصولات", date: "۱۴۰۲/۰۲/۱۵", field: 1, oldAmount: 528, newAmount: 968 },
            { id: "#25402", tableTable: "جدول محصولات", date: "۱۴۰۲/۰۲/۱۵", field: 1, oldAmount: 528, newAmount: 968 },
            { id: "#25402", tableTable: "جدول محصولات", date: "۱۴۰۲/۰۲/۱۵", field: 1, oldAmount: 528, newAmount: 968 },
            { id: "#25402", tableTable: "جدول محصولات", date: "۱۴۰۲/۰۲/۱۵", field: 1, oldAmount: 528, newAmount: 968 },
            { id: "#25402", tableTable: "جدول محصولات", date: "۱۴۰۲/۰۲/۱۵", field: 1, oldAmount: 528, newAmount: 968 },
        ]
    },
    reducers: {

    },
})

export const { viewTables, scoresTable, detailsOrders, detialsTable, viewModal } = managerDataSlice.actions
export default managerDataSlice.reducer