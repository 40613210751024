import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { orderTable as ot } from "src/redux/slices/adminDashboardReducer";
import { useSelector } from "react-redux";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AdminDashboard() {

    const orderTable = useSelector(ot)
    return (
        <>
            <HeaderPage title={"پیشخوان"} subtitle={"پیشخوان"} route={{ name: "پیشخوان", url: "/admin-dashboard" }} />

            <div id="admin-dashboard">

                <div id="row1">

                    <div className="section1">

                        <div className="about-admin">
                            <div className="part1">

                                <div className="welcome-text">
                                    <p>خوش آمدید !</p>
                                    <span>Fashion startup</span>
                                </div>
                                <div className="profileimage">

                                    <img src={process.env.PUBLIC_URL + "/assets/images/profile-img.png"} />
                                </div>

                            </div>
                            <div className="part2">
                                <div className="dc">
                                    <div className="avatar">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/avatar-1.jpg (1).png"} />
                                    </div>
                                    <div className="box-dc">
                                        <div className="date">
                                            <p>تاریخ ورود</p>
                                            <span>۱۴۰۲/۱۰/۲۷</span>
                                        </div>
                                        <div className="condition">
                                            <p>وضعیت کاربر</p>
                                            <span>فعال</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="nv">
                                    <div className="name">
                                        <p>زهرا امینی</p>
                                        <span>مدیر</span>
                                    </div>
                                    <div className="button-view">
                                        <Link to={"#"}>
                                            <span>مشاهده پروفایل</span>

                                            <i className="fa-light fa-arrow-left fa-xl"></i>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="income">
                            <div className="title-text">
                                <p>درآمد ماهانه</p>
                            </div>

                            <div className="box-price">

                                <div className="portion1">
                                    <div className="cost">

                                        <h4> ۴۵,۶۰۰ تومان</h4>
                                        <div className="percent">
                                            <i className="fa-light fa-arrow-left"></i>
                                            <span>12% </span>
                                            <span>از دوره قبلی</span>
                                        </div>
                                    </div>
                                    <div className="view-more">
                                        <Link to={"#"}>
                                            <span>مشاهده بیشتر</span>
                                            <i className="fa-light fa-arrow-left fa-l"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="portion2">
                                    <div className="progress-section">
                                        <div className="circle-progress-value"></div>
                                        <div className="circle-progress-circle"></div>
                                        <div className="circle-progress-text"></div>
                                        <circle-progress value="50" max="100"></circle-progress>

                                        <div className="test-pro">
                                            <circle-progress value="40" max="100"></circle-progress>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="text">
                                <span>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت </span>
                            </div>
                        </div>
                    </div>



                    <div className="section2">
                        <div className="part1">
                            <div className="portion1">
                                <div className="customers">
                                    <div className="text">
                                        <span>مشتریان فعال</span>
                                        <p>۳۴۵</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-light fa-ticket fa-xl"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="portion2">
                                <div className="income">
                                    <div className="text">
                                        <span>درآمد</span>
                                        <p>۱۰۰,۵۰۰ تومان</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-light fa-inbox fa-xl"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="portion3">
                                <div className="order">
                                    <div className="text">
                                        <span>سفارشات</span>
                                        <p>۱,۳۴۵</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-light fa-copy fa-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="part2">

                            <div className="title-section">

                                <p>آمار بازید وب سایت</p>

                                <div className="time">

                                    <div className="year">
                                        <Link to={"#"}>سال</Link>

                                    </div>
                                    <div className="month">
                                        <span>ماه</span>
                                    </div>
                                    <div className="week">
                                        <span>هفته</span>
                                    </div>
                                </div>
                            </div>

                            <div className="statistics-image">

                                <div id="chartt"></div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="row2">

                    <div className="title">
                        <p>آخرین سفارشان</p>
                    </div>
                    <div className="table-section">
                        <table className="lastest-order-table">

                            <thead>
                                <tr>
                                    <th className="box-chechbox-th">
                                        <div className="input-chechbox-th">
                                            <input className="form-check-input" type="checkbox" />
                                        </div>
                                    </th>
                                    <th>شناسه سفارش</th>
                                    <th>نام مشتری</th>
                                    <th>تاریخ</th>
                                    <th>مبلغ خرید</th>
                                    <th>وضعیت سفارش</th>
                                    <th>مشاهده جزئیات</th>

                                </tr>
                            </thead>
                            <tbody>
                                {orderTable.map((order) =>
                                    <tr className="even">
                                        <td className="box-chechbox-td">
                                            <div className="input-chechbox-td">
                                                <input type="checkbox" />
                                            </div>
                                        </td>

                                        <td className="order-id">
                                            <Link to={"#"}>{order.productId}</Link>
                                        </td>
                                        <td>{order.customerName}</td>
                                        <td>{order.date}</td>
                                        <td>{order.cost} تومان</td>
                                        <td className="box-status">
                                            {order.orderStatus != "موفق" ?
                                                <div className="status-color-r">
                                                    <span>ناموفق</span>
                                                </div>
                                                : <div className="status-color-g">
                                                    <span>موفق</span>
                                                </div>
                                            }


                                        </td>
                                        <td className="view-details">

                                            <Link to={"/details-order"}>
                                                <span>مشاهده جزئیات</span>
                                            </Link>
                                        </td>
                                    </tr>

                                )}

                                {/* <tr className="even">
                                    <td className="box-chechbox-td">
                                        <div className="input-chechbox-td">
                                            <input type="checkbox" />
                                        </div>
                                    </td>

                                    <td className="order-id">
                                        <Link to={"#"}>#SK3216</Link>
                                    </td>
                                    <td>mehdi nikzad</td>
                                    <td>۱۴۰۲/۰۸/۲۳</td>
                                    <td>۴۳۰.۰۰۰ تومان</td>
                                    <td className="box-status">
                                        <div className="status-color-y">
                                            <span>معلق</span>
                                        </div>
                                    </td>
                                    <td className="view-details">

                                        <Link to={"#"}>
                                            <span>مشاهده جزئیات</span>
                                        </Link>
                                    </td>
                                </tr> */}


                            </tbody>

                        </table>
                    </div>


                </div>




            </div>

        </>


    )
}

export default AdminDashboard