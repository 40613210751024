import HeaderPage from "../components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AddProduct() {
    const [data, setData] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedColor, setSelectedColor] = useState([])
    const [selectedSize, setSelectedSize] = useState([])
    const [selectedMaterial, setSelectedMaterial] = useState([])
    const txtName = useRef()
    const numPrice = useRef()
    const animatedComponents = makeAnimated();

    useEffect(() => {

        axios.get("http://127.0.0.1:8000/app/properties/value/view/").then(res => {
            setData(res.data.data)
        })

        axios.get("http://127.0.0.1:8000/app/categories/view/").then(res => {
            setCategories(res.data.data)
        })

    }, 0)

    function sCategories(t) {
        if (selectedCategories.findIndex((m) => m == t) != -1) {
            setSelectedCategories([...selectedCategories, t])
        } else {
            setSelectedCategories(selectedCategories.filter((m) => m != t));
        }
    }

    function sColor(t) {
        if (selectedColor.findIndex((m) => m == t) != -1) {
            setSelectedColor([...selectedColor, t])
        } else {
            setSelectedColor(selectedColor.filter((m) => m != t));
        }
    }

    function sSize(t) {
        if (selectedSize.findIndex((m) => m == t) != -1) {
            setSelectedSize([...selectedSize, t])
        } else {
            setSelectedSize(selectedSize.filter((m) => m != t));
        }
    }

    function sMaterial(t) {
        if (selectedMaterial.findIndex((m) => m == t) != -1) {
            setSelectedMaterial([...selectedMaterial, t])
        } else {
            setSelectedMaterial(selectedMaterial.filter((m) => m != t));
        }
    }

    return (
        <>
            <HeaderPage title={'افزودن محصول'} subtitle={'محصول'} route={{ name: "محصول", url: "/add-product" }} />

            <div id="admin-add-product">
                <div className="section-1">
                    <div className="section-1-head">
                        <span>اطلاعات کلی محصول</span>
                    </div>
                    <div className="section-1-main">
                        <div className="right-main">
                            <div className="field-section">
                                <div className="field-title">
                                    <span>نام محصول</span>
                                </div>
                                <div className="field">
                                    <input type="text" ref={txtName} placeholder="نام محصول را وارد کنید" />
                                </div>
                            </div>
                            <div className="field-section">
                                <div className="field-title">
                                    <span>قیمت محصول</span>
                                </div>
                                <div className="field">
                                    <input type="text" ref={numPrice} placeholder="قیمت محصول را وارد کنید" />
                                </div>
                            </div>

                            <div className="field-section">
                                <div className="field-title">
                                    <span>دسته بندی</span>
                                </div>
                                <div className="field">
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        onChange={(e) => sCategories(e.target.value)}
                                        options={categories.map((ct) =>{return { value: ct.name, label: ct.name }})}
                                    />
                                </div>
                            </div>

                            <div className="field-sec">
                                <div className="field-title">
                                    <span>توضیحات محصول</span>
                                </div>
                                <div className="field-secc">
                                    <div className="sec-up">
                                        <i className="fa-solid fa-underline"></i>
                                        <i className="fa-solid fa-italic"></i>
                                        <i className="fa-solid fa-bold"></i>
                                        <i className="fa-solid fa-align-justify"></i>
                                        <i className="fa-sharp fa-solid fa-align-right"></i>
                                        <i className="fa-solid fa-align-center"></i>
                                        <i className="fa-solid fa-list-ol"></i>
                                        <i className="fa-sharp fa-solid fa-list"></i>
                                        <i className="fa-solid fa-fill-drip"></i>
                                    </div>
                                    <div className="sec-down">
                                        <input type="text" placeholder="توضیحات محصول را وارد کنید" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="left-main">
                            {/* <div className="field-section">
                                <div className="field-title">
                                    <span>ویژگی های محصول(multi select)</span>
                                </div>
                                <div className="field">
                                    <select>
                                        <option></option>
                                    </select>
                                </div>
                            </div> */}
                            <div className="field-section">
                                <div className="field-title">
                                    <span>رنگ</span>
                                </div>
                                <div className="field">
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        onChange={(e) => sColor(e.target.value)}
                                        options={
                                            data.reduce((s, m) => {
                                                if (m.property == "color") {
                                                    return [...s, { value: m.value, label: m.value }]
                                                }
                                                return s
                                            }, [])
                                        }
                                    />
                                </div>
                            </div>
                            <div className="field-section">
                                <div className="field-title">
                                    <span>جنس</span>
                                </div>
                                <div className="field">
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={
                                            data.reduce((s, m) => {
                                                if (m.property == "material") {
                                                    return [...s, { value: m.value, label: m.value }]
                                                }
                                                return s
                                            }, [])
                                        }
                                    />
                                </div>
                            </div>
                            <div className="field-section">
                                <div className="field-title">
                                    <span>سایز</span>
                                </div>
                                <div className="field">
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={
                                            data.reduce((s, m) => {
                                                if (m.property == "size") {
                                                    return [...s, { value: m.value, label: m.value }]
                                                }
                                                return s
                                            }, [])
                                        }
                                    />
                                </div>
                            </div>
                            <div className="field-section">
                                <div className="field-title">
                                    <span>تعداد موجودی</span>
                                </div>
                                <div className="field">
                                    <input type="text" placeholder="تعداد موجودی را وارد کنید" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-2">
                    <div className="section-2-head">
                        <span>عکس محصول</span>
                    </div>
                    <div className="section-2-main">
                        <div className="main-bala">
                            <div className="upload-icon">
                                <i className="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                            </div>
                            <div className="upload-txt">
                                <span>فایل ها را اینجا رها کنید یا برای آپلود کلیک کنید.</span>
                            </div>
                        </div>
                        <div className="main-paiin">
                            <a href="#">افزودن</a>
                            <a href="#" style={{ backgroundColor: "#556EE6;" }}>حذف</a>
                        </div>
                    </div>
                </div>
                <div className="section-3">
                    <a href="#">ذخیره</a>
                    <a href="#" onClick={() => {
                        console.log(sCategories)
                        console.log(sColor)
                        console.log(sMaterial)
                        console.log(sSize)
                        axios.post("http://127.0.0.1:8000/app/products/create/",
                            {
                                name: txtName,
                                price: numPrice,
                                // categories:
                            }
                        ).then(res => {
                            setData(res.data.data)
                        })



                    }} style={{ backgroundColor: " #556EE6;" }}>حذف</a>
                </div>
            </div >



        </>
    )
}

export default AddProduct;