import { createSlice } from "@reduxjs/toolkit";


export const managerSlice = createSlice({
    name: "manager",
    initialState: {
        managerTable: [
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
            { id: "#SK2540", userName: "Amir Jafari", nationalCode: "۲۷۲۱۷۴۶۸۵۲", phone: "۰۹۰۱۵۱۳۷۵۶۲" },
        ]
    },

    reducers: {

    }
})
export const managersTable = (state) => state.manager.managerTable

export default managerSlice.reducer