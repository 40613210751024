import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}


function AddAddress() {
    return (
        <>
            <HeaderPage title={"افزودن آدرس"} subtitle={"افزودن آدرس"} route={{ name: "افزودن آدرس", url: "/add-address" }} />

            <div id="admin-add-address">
                <div id="section-1">
                    <div className="add-field">
                        <div className="add-title">
                            <span>افزودن کشور</span>
                        </div>
                        <div className="field-asli">
                            <div className="field-title">
                                <span>کشور</span>
                            </div>
                            <div className="field-section">
                                <input type="text" placeholder="نام کشور را وارد کنید" />
                            </div>
                        </div>
                    </div>
                    <div className="add-field">
                        <div className="add-title">
                            <span>افزودن استان</span>
                        </div>
                        <div className="field-asli">
                            <div className="fields">
                                <div className="field-1">
                                    <div className="field-title">
                                        <span>کشور</span>
                                    </div>
                                    <div className="field-section">
                                        <select>
                                            <option selected>کشور مورد نظر را انتخاب کنید</option>
                                            <option>ایران</option>
                                            <option>ترکیه</option>
                                            <option>دبی</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="field-2">
                                    <div className="field-title">
                                        <span>استان</span>
                                    </div>
                                    <div className="field-section">
                                        <input type="text" placeholder="نام استان را وارد کنید" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-field">
                        <div className="add-title">
                            <span>افزودن شهر</span>
                        </div>
                        <div className="field-asli">
                            <div className="fields">
                                <div className="field-3">
                                    <div className="field-title">
                                        <span>کشور</span>
                                    </div>
                                    <div className="field-section">
                                        <select>
                                            <option selected>کشور مورد نظر را انتخاب کنید</option>
                                            <option>ایران</option>
                                            <option>ترکیه</option>
                                            <option>دبی</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="field-4">
                                    <div className="field-title">
                                        <span>استان</span>
                                    </div>
                                    <div className="field-section">
                                        <select>
                                            <option selected>استان مورد نظر را انتخاب کنید</option>
                                            <option>تهران</option>
                                            <option>گیلان</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="field-5">
                                    <div className="field-title">
                                        <span>شهر</span>
                                    </div>
                                    <div className="field-section">
                                        <input type="text" placeholder="نام شهر را وارد کنید" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="section-2">
                    <Link to={""}>ثبت</Link>
                    <Link to={""} style={{ backgroundColor: "  #556EE6" }}>حذف</Link>
                </div>
            </div >
        </>
    )
}
export default AddAddress;