import { Outlet } from 'react-router';
import './pages/AddAddress'
import { Link } from 'react-router-dom';
import Footer from './components/main/layout/Footer';
import Nav from './components/main/layout/Nav';
import Sidebar from './components/main/layout/Sidebar';
import Store from './redux/Store';
import { loadModal, load as loadViewChanges } from './redux/slices/viewChangesReducer';
import { load as loadScores } from './redux/slices/scoresReducer';
import { loadModal as loadModalViewChange } from './redux/slices/viewChangesReducer';
import { load as loadTable } from './redux/slices/detailsOrderReducer';

// console.log(Store.getState())

export function loader(data) {
  return {
    loadViewChange: Store.dispatch(loadViewChanges(Store.getState().managerData.viewTables)),
    loadScore: Store.dispatch(loadScores(Store.getState().managerData.scoresTable)),
    loadModal: Store.dispatch(loadModalViewChange(Store.getState().managerData.viewModal)),
    loadDtails: Store.dispatch(loadTable(Store.getState().managerData.detialsTable))

  }
}

function App() {

  return (
    <>
      <Nav />
      <div id="conteiner-admin">
        <Sidebar />
        <div id="main-admin">
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default App;