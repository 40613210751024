import { createSlice } from "@reduxjs/toolkit";


export const customerSlice = createSlice({
    name: "customer",
    initialState: {

        customerTable: [
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: false, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: false, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: true, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
            { userId: "۵۴۳۵۵۷۸۹۷۶", name: " امیرحسین جعفری", status: false, phone: "۰۹۱۱۵۴۶۵۸۵۸" },
        ]
    },
    reducers: {

    }
})

export const customersTable = (state) => state.customer.customerTable

export default customerSlice.reducer