import HeaderPage from "../components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { propertyTable as pt } from "src/redux/slices/propertiesReducer";
import { useSelector } from "react-redux";
import PropertiesModal from "src/components/modals/PropertiesModal";
import AddPropertiesModal from "src/components/modals/AddPropertiesModal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Properties() {
    const propertyTable = useSelector(pt)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })
    const [isModalOpenAdd, setIsModalOpenAdd] = useState({ status: false, type: "add" })


    const [data, setData] = useState([...propertyTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(propertyTable.length / count)


    useEffect(() => {
        setData(propertyTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, propertyTable])

    useEffect(() => {
        setData(propertyTable.slice((page - 1) * count, page * count))
    }, [pages, page, propertyTable])


    useEffect(() => {
        setData(propertyTable)
    }, [propertyTable])
    return (
        <>
            <HeaderPage title={'ویژگی ها'} subtitle={'ویژگی ها'} route={{ name: "ویژگی ها", url: "/properties" }} />
            {isModalOpen.status && <PropertiesModal setOpen={setIsModalOpen} open={isModalOpen} />}
            {isModalOpenAdd.status && <AddPropertiesModal setOpen={setIsModalOpenAdd} open={isModalOpenAdd} />}
            <div id="properties-admin">
                <div id="prop-sec1">

                    <div className="search-bar">
                        <div className="search">
                            <label>
                                جستجو :
                                <input style={{ height: " 36px" }} />
                            </label>
                        </div>
                        <div className="show-page">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>
                    </div>
                    <div className="add-delete">
                        <a className="delete" href="#">حذف</a>
                        <a className="add" onClick={() => {
                            setIsModalOpenAdd({ status: true, type: "add" })
                        }} href="#">
                            <span>افزودن ویژگی‌ها</span>
                            <i className="fa-solid fa-plus fa-xs"></i>
                        </a>
                    </div>


                    <div className="prop-table">
                        <table style={{ borderCollapse: " collapse", borderColor: "#EFF2F7", width: "100%" }}>
                            <thead style={{ height: "49px", backgroundColor: "#EFF2F7" }}>
                                <th><input style={{ width: " 16px", height: " 16px" }} type="checkbox" checked="checked" /></th>
                                <th>شناسه</th>
                                <th>عنوان ویژگی</th>
                                <th>دسته‌بندی</th>
                                <th>...</th>
                            </thead>

                            <tbody style={{ textAlign: " center", verticalAlign: "middle" }}>
                                {data.map((property) =>
                                    <tr>
                                        <td><input style={{ width: " 16px", height: " 16px" }} type="checkbox" checked="checked" />
                                        </td>
                                        <td style={{ fontWeight: "700", direction: "ltr" }}>{property.id}</td>
                                        <td>{property.properiesTitle}</td>
                                        <td>{property.category}</td>
                                        <td onClick={() => {
                                            setIsModalOpen({ status: true, type: "add" })
                                        }}>
                                            <div><i className="fa-solid fa-plus fa-xs"></i></div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </div>


                    <div className="under-table">
                        <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={propertyTable.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>

                </div>
            </div >

        </>
    )
}
export default Properties;