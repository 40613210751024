import HeaderPage from "src/components/main/layout/HeaderPage"
import { Link } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function SingleProduct() {
    return (
        <>
            <HeaderPage title={'جزییات محصول '} subtitle={'جزییات محصول'} route={{ name: "جزییات محصول ", url: "/single-product" }} />
            <div id="single-product">
                <div className="product">
                    <div className="right">
                        <div className="img-holder">
                            <img src={process.env.PUBLIC_URL + "/assets/images/big image.jpg"} />
                            <img src={process.env.PUBLIC_URL + "/assets/images/image 8.jpg"} />
                            <img src={process.env.PUBLIC_URL + "/assets/images/big image.jpg"} />
                            <img src={process.env.PUBLIC_URL + "/assets/images/image 8.jpg"} />
                        </div>
                        <img src={process.env.PUBLIC_URL + "/assets/images/big image.jpg"} />

                    </div>
                    <div className="left">
                        <div> <h6 style={{ color: "rgba(85, 110, 230, 1)" }}>هدفون</h6>
                            <h4>هدفون بی سیم (مشکی)</h4></div>

                        <div className="stars">
                            <div className="right">
                                <i className="fa-solid fa-star" style={{ color: "#ffd500" }}></i>
                                <i className="fa-solid fa-star" style={{ color: "#ffd500" }}></i>
                                <i className="fa-solid fa-star" style={{ color: "#ffd500" }}></i>
                                <i className="fa-solid fa-star" style={{ color: "#ffd500" }}></i>
                                <i className="fa-solid fa-star"></i>
                            </div>
                            <h6 style={{ color: "rgba(105, 105, 105, 1)" }}>(بررسی ۱۵۲ مشتری)</h6>
                        </div>
                        <div>
                            <h6 style={{ color: "rgba(4, 175, 0, 1) " }}>۲۰% تخفیف</h6>
                            <div style={{ display: "flex " }} >
                                <p>قیمت محصول :</p>
                                <p style={{ color: " rgba(105, 105, 105, 1)", textDecoration: "lineThrough" }}>۷۸۰ هزار تومن</p>
                                <p>۶۵۰ هزار تومان</p>
                            </div>
                        </div>
                        <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ...</p>
                        <div className="dites">
                            <div className="right">
                                <h6>بی سیم</h6>
                                <h6>عمر باتری: 6 ساعت</h6>
                                <h6>برد بی سیم:  10 متر</h6>
                            </div>
                            <div className="right">
                                <h6>صدای بم</h6>
                                <h6>ضمانتنامه: 1 سال</h6>
                            </div>
                        </div>
                        <h5>رنگ:</h5>
                        <div className="imgs">
                            <div className="item">
                                <img src={process.env.PUBLIC_URL + "/assets/images/big image.jpg"} style={{ border: "1px solid rgba(85, 110, 230, 1)" }} />

                                <h6 style={{ color: " rgba(85, 110, 230, 1)" }}>مشکی</h6>
                            </div>
                            <div className="item">
                                <img src={process.env.PUBLIC_URL + "/assets/images/big image.jpg"} />
                                <h6>مشکی</h6>
                            </div>
                            <div className="item">
                                <img src={process.env.PUBLIC_URL + "/assets/images/big image.jpg"} />
                                <h6>مشکی</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <h5></h5>
                <div className="info">
                    <div className="right">
                        <h6>دسته بندی</h6>
                        <h6>برند </h6>
                        <h6>رنگ</h6>
                        <h6>اتصال</h6>
                        <h6>خلاصه گارانتی</h6>
                    </div>
                    <div className="left">
                        <h6>هدفون</h6>
                        <h6>JBL</h6>
                        <h6>مشکی</h6>
                        <h6>بلوتوث</h6>
                        <h6>1 سال</h6>
                    </div>
                </div>
            </div>

        </>
    )
}
export default SingleProduct