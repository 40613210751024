import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AddCategory() {
    return (
        <>
            <HeaderPage title={"افزودن دسته بندی"} subtitle={"افزودن دسته بندی"} route={{ name: "افزودن دسته بندی", url: "/add-category" }} />

            <form action="">

                <div id="afzodan-dastebandi">
                    <div className="section1">
                        <div className="top">
                            <span>عنوان دسته بندی</span>
                            <input type="text" placeholder="عنوان دسته بندی را وارد کنید"/>
                        </div>
                        <div className="bottom">
                            <span>در دسته بندی</span>
                            <input type="text" placeholder="multiselect"/>
                        </div>
                    </div>
                    <div className="section2">
                        <span>عکس دسته بندی</span>
                        <div className="imgs">
                            <div className="icons">
                                <i className="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                                <span>فایل ها را اینجا رها کنید یا برای آپلود کلیک کنید.</span>
                            </div>
                            <div className="buttons">
                                <div className="add">
                                    <Link to={"#"}>افزودن</Link>
                                    <Link to={"#"}>حذف</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttons2">
                        <div className="add">
                            <Link to={"#"}>افزودن</Link>
                            <Link to={"#"}>حذف</Link>
                        </div>
                    </div>
                </div>
            </form>

        </>


    )
}

export default AddCategory