import HeaderPage from "../components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { viewTable as vt } from "src/redux/slices/viewChangesReducer";
import ViewChangesModal from "src/components/modals/ViewChangesModal"
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({
    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,
});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function ViewChanges() {
    const dispatch = useDispatch()
    const viewTable = useSelector(vt)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })
    const [data, setData] = useState([...viewTable])

    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(viewTable.length / count)

    useEffect(() => {
        setData(viewTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, viewTable])

    useEffect(() => {
        setData(viewTable.slice((page - 1) * count, page * count))
    }, [pages, page, viewTable])

    useEffect(() => {
        setData(viewTable)
    }, [viewTable])
    return (
        <>
            <HeaderPage title={'مشاهده تغییرات'} subtitle={'مشاهده تغییرات'} route={{ name: "مشاهده تغییرات", url: "/view-changes" }} />
            {isModalOpen.status && <ViewChangesModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="moshahede-taghirat">
                <div className="search">
                    <div className="right">
                        <label for="">
                            <h6>جستجو</h6>
                            <input type="text" />
                        </label>
                    </div>
                    <div className="left">
                        <label>
                            ورودی
                            <Select
                                defaultValue="10"
                                style={{
                                    width: 200,
                                }}
                                onChange={(value) => { setCount(value) }}
                                options={[
                                    // { value: 5, label: '5' },
                                    { value: 10, label: '10' },
                                    { value: 15, label: '15' },
                                    { value: 20, label: '20' },
                                ]}
                            />

                            نمایش
                        </label>
                    </div>
                </div>
                <div className="moshahede-taghirat-table">
                    <table>
                        <thead>
                            <tr>
                                <th> <span>شناسه</span> </th>
                                <th><span>نام جدول</span></th>
                                <th><span>ادمین</span></th>
                                <th><span>تاریخ</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((view) =>
                                <tr onClick={() => {
                                    setIsModalOpen({ status: true, type: "add" })
                                }}>
                                    <td><span>{view.id}</span></td>
                                    <td><span>{view.tableName}</span></td>
                                    <td><span>{view.admin}</span></td>
                                    <td><span>{view.date}</span></td>

                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
                <div className="pagination-bottom">
                    <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                    <Pagination
                        className="rtl-pagination"
                        current={page}
                        total={viewTable.length}
                        pageSize={count}
                        onChange={(page) => setPage(page)}
                    />
                </div>
            </div>

        </>
    )
}
export default ViewChanges;