import { Link, useLocation } from "react-router-dom";


export function loader(data) {
    return 0
}

function HeaderPage({title,subtitle,route}) {

    const location = useLocation()

    return (
        <>
            <div id="header-admin">
                <div className="header-section">
                    <span className="page-title">{title}</span>
                    <div className="page-path">
                        <ol>
                            <li>
                                <Link to={location.pathname}>{subtitle}</Link>
                            </li>
                            <span>/</span>
                            <li>
                                <Link to={route.url}>{route.name}</Link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default HeaderPage