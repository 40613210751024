import { createSlice } from "@reduxjs/toolkit";


export const shoppingCartSlice = createSlice({

    name: "shoppingCart",
    initialState: {
        cartTable: [
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
            { purchaseId: "#SK2540", customerId: "#684mo", name: "امیر", family: "جعفری", startDate: "۱۴۰۲/۰۲/۱۵", endDate: "۱۴۰۲/۰۲/۳۰" },
        ],
        cartTableModal: [
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },
            { id: "#25402", productId: "شلوار مام فیت", category: "شلوار مردانه	", amount: 1, price: '۵۲۸ هزار تومان', color: "ذغالی", size: 43 },

        ]
    },
    reducers: {

    }
})

export const cartTables = (state) => state.shoppingCart.cartTable
export const cartModalData = (state) => state.shoppingCart.cartTableModal

export default shoppingCartSlice.reducer