import HeaderPage from "src/components/main/layout/HeaderPage"
import { Link } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { useSelector } from "react-redux";
import { customersTable as ct } from "src/redux/slices/customerReducecr";
import CustomerModal from "src/components/modals/CustomerModal";
import { useEffect, useState } from "react";
import { Select, Pagination } from 'antd';
import axios from 'axios'

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Customers() {
    const customersTable = useSelector(ct)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })

    const [data, setData] = useState([...customersTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(customersTable.length / count)
 

    useEffect(() => {
        setData(customersTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, customersTable])

    useEffect(() => {
        setData(customersTable.slice((page - 1) * count, page * count))
    }, [pages, page, customersTable])


    useEffect(() => {
        setData(customersTable)
    }, [customersTable])

    return (
        <>
            <HeaderPage title={'مشتریان '} subtitle={'مشتریان'} route={{ name: "مشتریان ", url: "/customers" }} />
            {isModalOpen.status && <CustomerModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="customers">
                <div className="ul-section">
                    <ul>
                        <li className="first-option">
                            <Link to="#">همه</Link>
                        </li>
                        <li>
                            <Link to="#">موجود</Link>
                        </li>
                        <li>
                            <Link to="#">ناموجود</Link>
                        </li>
                    </ul>
                </div>
                <div className="search">
                    <div className="right">
                        <label for="">
                            <h6>جستجو</h6>
                            <input type="text" />
                        </label>
                    </div>
                    <div className="left">
                        <label>
                            ورودی
                            <Select
                                defaultValue="10"
                                style={{
                                    width: 200,
                                }}
                                onChange={(value) => { setCount(value) }}
                                options={[
                                    // { value: 5, label: '5' },
                                    { value: 10, label: '10' },
                                    { value: 15, label: '15' },
                                    { value: 20, label: '20' },
                                ]}
                            />

                            نمایش
                        </label>
                    </div>
                </div>
                <div className="customer-btns">
                    <button>فعال</button>
                    <button>غیر فعال</button>
                </div>
                <div className="customers-table">
                    <table>
                        <thead>
                            <tr>
                                <th><input type="checkbox" name="" id="" /> </th>
                                <th> <span>شناسه کاربری</span> </th>
                                <th><span>نام و نام خانوادگی</span></th>
                                <th><span>فعال/غیرفعال</span></th>
                                <th><span>تلفن</span></th>
                                <th><span>...</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((customer) =>
                                <tr>
                                    <td><input type="checkbox" name="" id="" /> </td>
                                    <td><span>{customer.userId}</span></td>
                                    <td><span> {customer.name}</span></td>
                                    <td>{customer.status != true ? <span>غیرفعال</span> : <span>فعال</span>}</td>
                                    <td><span>{customer.phone}</span></td>
                                    <td onClick={() => {
                                        setIsModalOpen({ status: true, type: "add" })
                                    }}><div><span>مشاهده جزییات</span></div></td>
                                </tr>
                            )}
                        </tbody>


                    </table>
                </div>
                <div className="pagination-bottom">
                    <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                    <Pagination
                        className="rtl-pagination"
                        current={page}
                        total={customersTable.length}
                        pageSize={count}
                        onChange={(page) => setPage(page)}
                    />
                </div>
            </div>


        </>
    )
}
export default Customers