import { Link } from "react-router-dom";
import HeaderPage from "src/components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { addManagerTable as amt } from "src/redux/slices/addManagerReducer";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AddManagers() {

    const addManagerTable = useSelector(amt)
    const [data, setData] = useState([...addManagerTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(addManagerTable.length / count)


    useEffect(() => {
        setData(addManagerTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, addManagerTable])

    useEffect(() => {
        setData(addManagerTable.slice((page - 1) * count, page * count))
    }, [pages, page, addManagerTable])


    useEffect(() => {
        setData(addManagerTable)
    }, [addManagerTable])
    return (
        <>
            <HeaderPage title={"افزودن مدیر"} subtitle={"افزودن مدیر"} route={{ name: "افزودن مدیر", url: "/add-managers" }} />


            <div id="admin-add-managers">
                <div id="admin-section-one">
                    <div className="search-section">
                        <div className="search-box">
                            <div className="search-title">
                                <span>جستجو :</span>
                            </div>
                            <input type="search" />
                        </div>
                        <div className="show-items">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>
                    </div>
                    <div className="admin-button">
                        <button>تنظیم به عنوان مدیر</button>
                    </div>
                    <div className="table-section">
                        <table className="admin-table">
                            <thead>
                                <tr>
                                    <th>
                                        <div>
                                            <input type="checkbox" style={{ width: "16px", height: "16px" }} />
                                        </div>
                                    </th>
                                    <th>شناسه کاربری</th>
                                    <th>نام و نام خانوادگی</th>
                                    <th>کد ملی</th>
                                    <th>تلفن</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((manager) =>
                                    <tr>
                                        <td>
                                            <div><input type="checkbox" style={{ width: "16px", height: "16px" }} /></div>
                                        </td>
                                        <td>{manager.userId}</td>
                                        <td>{manager.name}</td>
                                        <td>{manager.nationalCode}</td>
                                        <td>{manager.phone}</td>

                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-section">
                        <div className="right-pagination">
                            <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                        </div>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={addManagerTable.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                </div>
            </div>
        </>


    )
}

export default AddManagers