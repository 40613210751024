import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { Select, Pagination } from 'antd';
import { cartModalData as ct } from 'src/redux/slices/shoppingCartReducer';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function ShoppingCartModal({ open, setOpen }) {

    const cartModalData = useSelector(ct)
    const [data, setData] = useState([...cartModalData])
    const [count, setCount] = useState(4)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(cartModalData.length / count)


    const handleCancel = () => {
        setOpen(false)
    }
    useEffect(() => {
        setData(cartModalData.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, cartModalData])

    useEffect(() => {
        setData(cartModalData.slice((page - 1) * count, page * count))
    }, [pages, page, cartModalData])


    useEffect(() => {
        setData(cartModalData)
    }, [cartModalData])

    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div className="section-bala">
                    <span>جزییات سبد خرید</span>
                    {/* <i className="fa-solid fa-xmark fa-xl"></i> */}
                </div>
                <div id="cart-detail-modal">

                    <div className="section-paiin">
                        <div id="section-table">
                            <div className="search-section">
                                <div className="search">
                                    <span>جستجو :</span>
                                    <input type="search" />
                                </div>
                                <a href="#">جستجو</a>
                            </div>
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>شناسه</th>
                                            <th>نام محصول</th>
                                            <th>دسته بندی</th>
                                            <th>تعداد</th>
                                            <th>قیمت</th>
                                            <th>رنگ</th>
                                            <th>سایز</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((data) =>
                                            <tr>
                                                <td>{data.id}</td>
                                                <td>{data.productId}</td>
                                                <td>{data.category}</td>
                                                <td>{data.amount}</td>
                                                <td>{data.price}</td>
                                                <td>{data.color}</td>
                                                <td>{data.size}</td>
                                            </tr>

                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="cart-pagination">
                            <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                            <Pagination
                                className="rtl-pagination"
                                current={page}
                                total={cartModalData.length}
                                pageSize={count}
                                onChange={(page) => setPage(page)}

                            />
                        </div>
                    </div>
                </div>

            </Modal >


        </>
    )
}

export default ShoppingCartModal;