import HeaderPage from "../components/main/layout/HeaderPage";
import ProgressBar from "@badrap/bar-of-progress";
import { transactions as trs, } from "../redux/slices/addFinancialReducer";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import React from 'react';
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({

    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function AddFinancial() {
    const transactions = useSelector(trs)
    const [data, setData] = useState([...transactions])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(transactions.length / count)

    useEffect(() => {
        setData(transactions.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, transactions])

    useEffect(() => {
        setData(transactions.slice((page - 1) * count, page * count))
    }, [pages, page, transactions])

    useEffect(() => {
        setData(transactions)
    }, [transactions])

    return (
        <>
            <HeaderPage title={'افزودن مالی'} subtitle={'مالی'} route={{ name: "مالی", url: "/add-financial" }} />

            <div id="admin-wallet">

                <div id="wallet-secttion1">

                    <div className="first-row">


                        <div className="returned">
                            <div className="icon">
                                <i className="fa-solid fa-circle-plus"></i>
                            </div>

                            <div className="text">
                                <span>مجموع مرجوعی</span>
                                <p>۴,۳۰۰,۰۰۰ تومان</p>
                            </div>
                        </div>

                        <div className="sale">
                            <div className="icon">
                                <i className="fa-solid fa-circle-plus"></i>
                            </div>

                            <div className="text">
                                <span>مجموع فروش</span>
                                <p>۴,۳۰۰,۰۰۰ تومان</p>
                            </div>
                        </div>

                        <div className="transaction">
                            <div className="icon">
                                <i className="fa-solid fa-circle-plus"></i>
                            </div>

                            <div className="text">
                                <span>مجموع تراکنش ها</span>
                                <p>۴,۳۰۰,۰۰۰ تومان</p>
                            </div>
                        </div>

                    </div>

                    <div className="second-row">
                        <div className="check-wallet">

                            <div className="part1">

                                <div className="total-sales1">
                                    <div className="text">
                                        <span>مجموع فروش این ماه</span>
                                    </div>
                                    <div className="price">
                                        <div>
                                            <span>+ ۱.۳ %</span>
                                        </div>
                                        <p>۵,۲۰۰,۱۵۰ تومان</p>
                                    </div>
                                </div>
                                <div className="total-sales2">
                                    <div className="text">
                                        <span>مجموع فروش ماه گذشته</span>
                                    </div>
                                    <div className="price">

                                        <p>۵,۲۰۰,۱۵۰ تومان</p>
                                    </div>
                                </div>

                            </div>
                            <hr />

                            <div className="part2">

                                <div className="title">
                                    <span>در این ماه</span>
                                </div>

                                <div className="content">

                                    <div className="deposite">
                                        <div className="icon"><i className="fa-light fa-wallet"></i></div>
                                        <div className="text">
                                            <span>واریزی ها</span>
                                        </div>
                                        <div className="price">
                                            <p>۴,۳۰۰,۰۰۰</p>
                                        </div>
                                        <div className="deposite-button">
                                            <a href="#">واریز</a>
                                        </div>
                                    </div>

                                    <div className="receive">
                                        <div className="icon">
                                            <i className="fa-light fa-file-import fa-rotate-90"></i>
                                        </div>
                                        <div className="text">
                                            <span>دریافتی ها</span>
                                        </div>
                                        <div className="price">
                                            <p>۴,۳۰۰,۰۰۰</p>
                                        </div>
                                        <div className="receive-button">
                                            <a href="#">دریافت</a>
                                        </div>
                                    </div>

                                    <div className="send">
                                        <div className="icon">
                                            <i className="fa-light fa-paper-plane-top"></i>
                                        </div>
                                        <div className="text">
                                            <span>ارسال ها</span>
                                        </div>
                                        <div className="price">
                                            <p>۴,۳۰۰,۰۰۰</p>
                                        </div>
                                        <div className="send-button">
                                            <a href="#">ارسال</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="check-statistics">
                            <div className="title">
                                <p>بررسی اجمالی</p>
                            </div>

                            <div className="statistics-table">


                            </div>
                        </div>
                    </div>

                </div>


                <div id="wallet-secttion2">

                    <div className="title">
                        <p>تراکنش ها</p>
                    </div>

                    <div className="ul-section">
                        <ul>
                            <li className="first-option">
                                <a className="abi" href="#">همه</a>
                            </li>
                            <li>
                                <a href="#">انتقال</a>
                            </li>
                            <li>
                                <a href="#">دریافت</a>
                            </li>
                            <li>
                                <a href="#">واریز</a>
                            </li>
                        </ul>
                    </div>



                    <div className="table-section">

                        <div className="row">
                            <div className="option1">

                                <label>
                                    جستجو :
                                    <input type="search" placeholder="" />
                                </label>
                            </div>

                            <div className="option2">

                                <label>
                                    ورودی
                                    <Select
                                        defaultValue="10"
                                        style={{
                                            width: 200,
                                        }}
                                        onChange={(value) => { setCount(value) }}
                                        options={[
                                            // { value: 5, label: '5' },
                                            { value: 10, label: '10' },
                                            { value: 15, label: '15' },
                                            { value: 20, label: '20' },
                                        ]}
                                    />

                                    نمایش
                                </label>

                            </div>
                        </div>

                        <table className="transaction-table">
                            <thead>
                                <tr>
                                    <th>شناسه تراکنش</th>
                                    <th>نام کاربری</th>
                                    <th>تاریخ</th>
                                    <th>نوع</th>
                                    <th>وضعیت</th>
                                    <th>مبدا تراکنش</th>
                                    <th>مبلغ</th>
                                </tr>
                            </thead>


                            <tbody>
                                {data.map((transaction) =>

                                    <tr className="odd">
                                        <td className="transaction-id-option">
                                            <a href="#">{transaction.transitionId}</a>
                                        </td>
                                        <td>{transaction.userName}</td>
                                        <td>{transaction.date}</td>
                                        <td>{transaction.type}</td>
                                        < td className="box-status">
                                            {transaction.orderStatus != true ?
                                                <div className="status-color-r">
                                                    <span>ناموفق</span>
                                                </div>
                                                : <div className="status-color-g">
                                                    <span>موفق</span>
                                                </div>}
                                        </td>
                                        <td>{transaction.transactionOrigin}</td>
                                        <td>{transaction.price} تومان</td>



                                    </tr>

                                )}




                            </tbody>

                        </table>

                        <div className="show-table-pages">
                            <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                            <Pagination
                                className="rtl-pagination"
                                current={page}
                                total={transactions.length}
                                pageSize={count}
                                onChange={(page) => setPage(page)}
                            />

                        </div>

                    </div>
                </div>
            </div>


        </>
    )

}


export default AddFinancial;