import { createSlice } from "@reduxjs/toolkit";

export const adminDashboardSlice = createSlice({

    name: "adminDashboard",

    initialState: {
        ordersTable: [
            { productId: "#SK3216", customerName: "amir jfari", date: "۱۴۰۲/۰۸/۲۳", cost: "۴۳۰.۰۰۰", orderStatus: "موفق" },
            { productId: "#SK3216", customerName: "amir jfari", date: "۱۴۰۲/۰۸/۲۳", cost: "۴۳۰.۰۰۰", orderStatus: "ناموفق" },
            { productId: "#SK3216", customerName: "amir jfari", date: "۱۴۰۲/۰۸/۲۳", cost: "۴۳۰.۰۰۰", orderStatus: "موفق" },
            { productId: "#SK3216", customerName: "amir jfari", date: "۱۴۰۲/۰۸/۲۳", cost: "۴۳۰.۰۰۰", orderStatus: "ناموفق" },
        ],
        detailsTable: [
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
            { orderId: "#SK2540", productName: "شلوار کتان", date: "۱۴۰۲/۰۸/۱۲", price: "۴۳۰.۰۰۰" },
        ]

    },

    reducers: {

    }

})

export const orderTable = (state) => state.adminDashboard.ordersTable
export const detailTable = (state) => state.adminDashboard.detailsTable

export default adminDashboardSlice.reducer