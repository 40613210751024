import { Button, Modal } from 'antd';

function CategoryDtailsModal({ open, setOpen }) {
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div id="category-detail-modal">

                    <div className="section-bala">
                        <span>جزئیات دسته بندی</span>
                    </div>
                    <div className="section-paiin">
                        <div className="part">
                            <span>شلوار مردانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="part">
                            <span>پیراهن مردانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="part">
                            <span>شلوار زنانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="part">
                            <span>شلوار مردانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="part">
                            <span>پیراهن مردانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="part">
                            <span>شلوار زنانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="part">
                            <span>شلوار مردانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                        <div className="part">
                            <span>شلوار زنانه</span>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>

                    <div class="sec3">
                        <span onClick={() => {
                            setOpen(false)
                        }} class="delete">حذف</span>
                        <span class="accept">ثبت</span>
                    </div>

                </div>
            </Modal >


        </>
    )
}

export default CategoryDtailsModal;