

function Footer() {
    return (
        <>
            <footer id="footer-admin">
                <div className="footnote">

                    <div className="part1">
                        <span>Design & Develop by Aryorithm</span>
                    </div>
                    <div className="part2">
                        <span>2024 © Fashion Startup</span>
                    </div>
                </div>
            </footer>

        </>
    )
}
export default Footer;