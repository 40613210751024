import { createSlice } from "@reduxjs/toolkit";


export const ordersSlice = createSlice({
    name: "orders",
    initialState: {

        ordersTable: [
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
            { productId: "#SK2540", userName: "امیرحسین جعفری", date: "۱۴۰۲/۰۲/۱", price: "۴۳۰.۰۰۰ " },
        ]

    },
    reducers: {


    }


})

export const orderTable = (state) => state.orders.ordersTable
export default ordersSlice.reducer