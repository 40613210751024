import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Store from 'src/redux/Store';
import router from 'src/routes/routes';
import 'src/assets/css/style.css'
import 'src/assets/vendor/fontawsome/css/all.min.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <RouterProvider router={router} />
  </Provider>

);

reportWebVitals();