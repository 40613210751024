import HeaderPage from "../components/main/layout/HeaderPage"
import ProgressBar from "@badrap/bar-of-progress";
import { orderTable as ot } from "src/redux/slices/ordersReducer";
import { useSelector } from "react-redux";
import OrderModal from "src/components/modals/OrderModal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Select, Pagination } from 'antd';

const progress = new ProgressBar({
    size: 2,
    color: "#29e",
    className: "bar-of-progress",
    delay: 80,

});

export function loader() {
    progress.start();
    setTimeout(() => {
        progress.finish();
    }, 1000)
    return true
}

function Orders() {
    const orderTable = useSelector(ot)
    const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "add" })

    const [data, setData] = useState([...orderTable])
    const [count, setCount] = useState(10)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const countPage = Math.ceil(orderTable.length / count)


    useEffect(() => {
        setData(orderTable.slice(0, count))
        setPage(1)

        let pgs = []
        for (let i = 1; i <= countPage; i++) {
            pgs.push(i)
        }
        setPages(pgs)
    }, [count, orderTable])

    useEffect(() => {
        setData(orderTable.slice((page - 1) * count, page * count))
    }, [pages, page, orderTable])


    useEffect(() => {
        setData(orderTable)
    }, [orderTable])
    return (
        <>
            <HeaderPage title={'سفارشات'} subtitle={'سفارشات'} route={{ name: "سفارشات", url: "/orders" }} />
            {isModalOpen.status && <OrderModal setOpen={setIsModalOpen} open={isModalOpen} />}

            <div id="orders-admin">
                <div id="orders-sec1">
                    <div className="search-bar">
                        <div className="search">
                            <label>
                                جستجو :
                                <input type="text" style={{ height: "36px" }} />
                            </label>
                        </div>
                        <div className="show-page">
                            <label>
                                ورودی
                                <Select
                                    defaultValue="10"
                                    style={{
                                        width: 200,
                                    }}
                                    onChange={(value) => { setCount(value) }}
                                    options={[
                                        // { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                    ]}
                                />

                                نمایش
                            </label>
                        </div>
                    </div>


                    <div className="orders-table">
                        <table style={{ borderCollapse: "collapse", borderColor: "#EFF2F7", width: "100%" }}>
                            <thead style={{ height: "49px", backgroundColor: " #EFF2F7" }}>
                                <th>شناسه سفارش</th>
                                <th>نام مشتری</th>
                                <th>تاریخ</th>
                                <th>مبلغ خرید</th>
                                <th>مشاهده جزئیات</th>
                            </thead>

                            <tbody style={{ textAlign: "center", verticalAlign: "middle" }}>
                                {data.map((order) =>
                                    <tr>
                                        <td style={{ fontWeight: "700", direction: " ltr" }}>{order.productId}</td>
                                        <td>{order.userName}</td>
                                        <td>{order.date}</td>
                                        <td>{order.price} تومان</td>
                                        <td onClick={() => {
                                            setIsModalOpen({ status: true, type: "add" })
                                        }}>
                                            <a href="#"><span>مشاهده جزئیات</span></a>
                                        </td>
                                    </tr>
                                )}

                            </tbody>

                        </table>
                    </div>

                    <div className="under-table">
                        <span>نمایش ۱ تا {data.length} از {data.length} ورودی</span>
                        <Pagination
                            className="rtl-pagination"
                            current={page}
                            total={orderTable.length}
                            pageSize={count}
                            onChange={(page) => setPage(page)}
                        />
                    </div>

                </div>
            </div >

        </>
    )
}

export default Orders;