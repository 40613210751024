import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Store from 'src/redux/Store';

export const viewChangesSlice = createSlice({
    name: "viewChanges",
    initialState: {
        viewTables: [],
        viewModal: []
    },
    reducers: {
        load: (state, action) => {
            state.viewTables = action.payload
        },
        loadModal: (state, action) => {
            state.viewModal = action.payload
        }
    },
})


export const { load, loadModal } = viewChangesSlice.actions
export const viewTable = (state) => state.viewChanges.viewTables
export const viewModals = (state) => state.viewChanges.viewModal

export default viewChangesSlice.reducer