import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

function DiscountsModal({ open, setOpen }) {
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onCancel={handleCancel}>
                <div class="section-bala">
                    <span>جزئیات تخفیف</span>
                    {/* <i class="fa-solid fa-xmark fa-xl"></i> */}
                </div>
                <div id="discount-modal-detail">
                    <div class="section-paiin">
                        <div class="gozine">پیراهن مردانه</div>
                        <div class="gozinee">کت</div>
                        <div class="gozineee">تی شرت مردانه</div>
                        <div class="gozinee">کت</div>
                        <div class="gozine">شلوار مردانه</div>
                        <div class="gozineee">تی شرت مردانه</div>
                        <div class="gozine">پیراهن مردانه</div>
                        <div class="gozine">شلوار مردانه</div>
                        <div class="gozinee">کت</div>
                        <div class="gozine">پیراهن مردانه</div>
                        <div class="gozineee">تی شرت مردانه</div>
                        <div class="gozinee">کت</div>
                        <div class="gozineee">لباس بچه گانه</div>
                        <div class="gozinee">کت</div>
                    </div>
                </div>

            </Modal >


        </>
    )
}

export default DiscountsModal;